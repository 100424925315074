import React from 'react';

import { formatDate } from '../../../../../utils/Helper';
import useAppStateStore from '../../../../../store/AppState.store';
import useWalkthroughStore from '../../../../../store/Walkthrough.store';

import './IntroScreen.scss';

import Screen, { ScreenBody } from '../../../atoms/Screen/Screen';
import Button from '../../../atoms/Button/Button';
import LinkCTA from '../../../atoms/Link/Link';

const IntroScreen = () => {
  const { description, date } = useWalkthroughStore().data;
  const {
    data: { isNavigatingWalkthrough, copy },
    actions: { handleShowTour, handleStartWalkthrough },
  } = useAppStateStore();
  const introLabel = copy.screens.introduction;

  const showDate = pos => (
    <div className={`wt-date wt-title-text wt-title-text--6 wt-date--${pos} wt-date--desktop`}>{formatDate(date)}</div>
  );

  return (
    <>
      {!isNavigatingWalkthrough && showDate('top')}
      <Screen title={introLabel.title} date={date} classes="wt-screen--intro">
        <ScreenBody>
          <div className="wt-intro" dangerouslySetInnerHTML={{ __html: description }}></div>

          {isNavigatingWalkthrough && showDate('bottom')}

          {!isNavigatingWalkthrough && (
            <div className="wt-intro__cta">
              <div className="wt-form-input">
                <Button
                  label={introLabel.start_cta_label}
                  classes="submit-btn"
                  onClick={handleStartWalkthrough}
                />
              </div>
              <div className="wt-form-input" style={{ textAlign: 'center' }}>
                <LinkCTA
                  label={introLabel.review_cta_label}
                  cta
                  onClick={handleShowTour}
                />
              </div>
            </div>
          )}
        </ScreenBody>
      </Screen>
    </>
  );
};

export default IntroScreen;
