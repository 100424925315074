import React from 'react';
// import PropTypes from 'prop-types';

import useAppStateStore from '../../../../store/AppState.store';

import Modal from '../../atoms/Modal/ConfirmModal';
import Button from '../../atoms/Button/Button';
import Heading from '../../atoms/Heading/Heading';
import Text from '../../atoms/Text/Text';

const SkipModal = ({ isOpen }) => {
  const {
    data: { copy },
    actions: { handleExitTour, handleSkipTour },
  } = useAppStateStore();
  const confirmLabel = copy.confirmation.skip;

  return (
    <div className="modal-confirm modal-confirm--exit">
      <Modal isModalOpen={isOpen} onClose={handleSkipTour}>
        <Heading level="2" classes="wt-title-text--3 wt-confirm-modal__confirm-heading">
          {confirmLabel.title}
        </Heading>
        {confirmLabel.description && (
          <Text tag="p" classes="wt-confirm-modal__description">
            {confirmLabel.description}
          </Text>
        )}
        <div className="wt-confirm-modal__controls">
          <Button
            theme="light"
            classes="wt-confirm-modal__cta-1"
            onClick={handleExitTour}
            label={confirmLabel.cta_confirm}
          />
          <Button
            theme="light"
            classes="wt-confirm-modal__cta-2"
            onClick={handleSkipTour}
            label={confirmLabel.cta_deny}
          />
        </div>
      </Modal>
    </div>
  );
};

// SkipModal.propTypes = {};

export default SkipModal;
