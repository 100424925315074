/* eslint-disable max-len */
import React from 'react';
import FocusTrap from 'focus-trap-react';

import useAppStateStore from '../../../../store/AppState.store';
import useModalStore from '../../../../store/Modal.store';

import './ConfirmModal.scss';

const Modal = ({ children }) => {
  const { lastActiveMenuElement, copy } = useAppStateStore.getState().data;
  const { isModalOpen } = useModalStore().data;

  return (
    <FocusTrap
      focusTrapOptions={{
        onActivate: () => {
          const modalMain = document.querySelector('.wt-ftue .wt-modal');
          const cookieNotice = document.querySelector('#cookie-notice');

          if (modalMain) {
            modalMain.setAttribute('aria-hidden', 'true');
          }

          if (cookieNotice) {
            cookieNotice.setAttribute('aria-hidden', 'true');
          }
        },
        onDeactivate: () => {
          const modalMain = document.querySelector('.wt-ftue .wt-modal');
          const cookieNotice = document.querySelector('#cookie-notice');

          setTimeout(() => {
            if (lastActiveMenuElement) {
              lastActiveMenuElement.focus();
            }
          }, 100);

          if (modalMain) {
            modalMain.removeAttribute('aria-hidden');
          }

          if (cookieNotice) {
            cookieNotice.removeAttribute('aria-hidden');
          }
        },
        initialFocus: () => {
          const initialFocusable = document.querySelector('.wt-confirm-modal__confirm-heading');
          if (initialFocusable) {
            initialFocusable.setAttribute('tabindex', '0');
            return initialFocusable;
          }

          return document.querySelector('.wt-confirm-modal button');
        },
      }}
      active={isModalOpen}
    >
      <div
        className={[
          'wt-confirm-modal',
          isModalOpen ? 'wt-confirm-modal--is-open' : '',
        ].join(' ')}
        aria-hidden={isModalOpen ? 'false' : 'true'}
        aria-describedby="dialog_desc"
      >
        <div className="wt-container">
          <div className="wt-confirm-modal__content">
            <div className="wt-confirm-modal__header">
              <div className="wt-confirm-modal__logo">
                <h2 className="wt-confirm-modal__header-text">{copy.brand}</h2>
              </div>
            </div>
            <div className="wt-confirm-modal__panel">{children}</div>
          </div>
        </div>
      </div>
    </FocusTrap>
  );
};

export default Modal;
