/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import useAppStateStore from '../../../../store/AppState.store';

import { formatDate } from '../../../../utils/Helper';
import a11yFocusTrap from '../../../../utils/FocusTrap';

import './Screen.scss';

export const ScreenBody = ({ children }) => (
  <div className="wt-screen__body">{children}</div>
);

export const ScreenFooter = ({ children }) => (
  <div className="wt-screen__footer">{children}</div>
);

const Screen = ({ title, date = null, children, classes }) => {
  const { lastActiveMenuElement } = useAppStateStore.getState().data;
  const classNames = ['wt-screen', classes].join(' ');

  const showDate = pos => (
    <div className={`wt-date wt-title-text wt-title-text--6 wt-date--${pos} wt-date--mobile`}>{formatDate(date)}</div>
  );

  useEffect(() => {
    a11yFocusTrap.trapFocus(
      document.querySelector('.wt-screen'),
      lastActiveMenuElement,
      false,
    );
    return () => {
      a11yFocusTrap.unTrapFocus();
    };
  }, []);

  return (
    <div className={classNames} tabIndex="-1">
      {title && (
        <div className="wt-screen__header" role="heading" aria-level="2" tabIndex="0">
          {date && showDate('top')}
          <div className="wt-title-text wt-title-text--6">{title}</div>
        </div>
      )}
      {children}
    </div>
  );
};

Screen.propTypes = {
  title: PropTypes.string,
  classes: PropTypes.string,
};

Screen.defaultProps = {
  title: null,
  classes: null,
};

export default Screen;
