/* eslint-disable max-len */
/* eslint-disable implicit-arrow-linebreak */
import create from 'zustand';
import { devtools } from 'zustand/middleware';

import * as API from '../utils/APIs';
import * as Helper from '../utils/Helper';

import useModalStore from './Modal.store';
import useWalkthroughStore from './Walkthrough.store';

const { handleModalToggle } = useModalStore.getState().actions;

const { origin, pathname, search, href } = window.location;
const urlParams = new URLSearchParams(search);
const isCreator = (Helper.getUrlParam('context') === 'edit')
  || pathname.split('/').includes('create');
const creatorMode = isCreator ? (pathname.split('/').includes('create') ? 'add' : 'edit') : null;
const hasURLParam = Helper.getUrlParam('url');
const leadURL = hasURLParam ? `${origin}/${urlParams.get('url')}` : null;
const isTemplatePreviewing = (Helper.getUrlParam('context') === 'preview');
const isTemplateDuplicating = (Helper.getUrlParam('context') === 'duplicate');
const isSharingOnly = Helper.getUrlParam('share') !== null && true;

const useStore = create(devtools((set, get) => ({
  data: {
    userType: isCreator ? 1 : 2, // [1: creator, 2: client]
    creatorMode,
    currentUserID: null,
    currentPageViewing: hasURLParam ? leadURL : origin, // Use as reference for other components
    defaultFramePage: hasURLParam ? leadURL : origin, // Use solely to load iFrame pages
    currentPageViewingID: null,
    currentWalkthroughItemIndex: 0,
    currentScreenViewing: '',
    creatorDefaultScreen: 'InfoScreen',
    clientDefaultScreen: 'IntroScreen',
    walkthroughItemsTotal: 0,

    isSharingOnly,
    isPreviewMode: false,
    isPanelCollapsed: false,
    isViewingTour: false, // If true, show FTUE modal
    isSkippingTour: false, // If true, show Skio Confirmation Modal
    isNavigatingWalkthrough: false,
    hasFinishedWalkthrough: false,
    isExitingWalkthrough: false,
    hasExitedWalkthrough: false, // If true, show Return to WT button
    isClosingWalkthrough: false,
    isDuplicatingWalkthrough: false,
    isDeletingWalkthrough: false,
    isSharingWalkthrough: isSharingOnly,
    copy: {},
    isPreviewingTemplateMode: isTemplatePreviewing,
    isDuplicatingTemplate: isTemplateDuplicating,
    isViewingTemplate: isTemplatePreviewing || isTemplateDuplicating,
  },
  actions: {
    // =======================
    // API ACTION CALLS
    // =======================
    handleGetDictionaryApi: async () => {
      return await API.getDictionary();
    },
    handleGetWalkthroughApi: async id => {
      return await API.getWalkthrough(id);
    },
    handleGetWalkthroughEditApi: async id => {
      return await API.getWalkthroughEdit(id);
    },
    handleGetWalkthroughListApi: async () => {
      const data = await API.getExistingWalkthroughList();
      return data;
    },
    handleGetUsersApi: async () => {
      const data = Promise.allSettled([
        API.getUsers_Authors(),
        API.getUsers_Subs(),
      ])
        .then(res => ({
          authors: res[0].value,
          subs: res[1].value,
        }));

      return data;
    },

    // =======================
    // STATE ACTIONS SETTERS
    // =======================
    handleSetCopy: data => {
      set(s => ({
        data: {
          ...s.data,
          copy: data,
        },
      }));
    },
    handleSetUsers: data => {
      set(s => ({
        data: {
          ...s.data,
          users: data,
        },
      }));
    },
    handleSetInitialScreen: () => {
      const { items } = useWalkthroughStore.getState().data;
      set(s => ({
        data: {
          ...s.data,
          currentPageViewing: items ? items[0].link : (hasURLParam ? leadURL : origin),
          defaultFramePage: items ? items[0].link : (hasURLParam ? leadURL : origin),
          currentScreenViewing:
            get().data.userType === 1
              ? get().data.creatorDefaultScreen
              : get().data.clientDefaultScreen,
          walkthroughItemsTotal: items ? items.length : 0,
          isNavigatingWalkthrough: get().data.userType === 1 && creatorMode === 'edit',
        },
      }));
    },
    handlePanelCollapse: () => {
      set(s => ({
        data: {
          ...s.data,
          isPanelCollapsed: !s.data.isPanelCollapsed,
        },
      }));
    },
    handleShowScreen: e => {
      const screenToShow = e.currentTarget.dataset.screen;
      const ariaLabel = e.currentTarget.getAttribute('aria-label');
      const panelCollapsed = get().data.isPanelCollapsed;
      const { title } = useWalkthroughStore.getState().data;

      if (panelCollapsed) {
        set(s => ({
          data: {
            ...s.data,
            currentScreenViewing: screenToShow,
            isPanelCollapsed: false,
            currentScreenLabel: ariaLabel,
          },
        }));
      } else {
        set(s => ({
          data: {
            ...s.data,
            currentScreenViewing: s.data.currentScreenViewing === screenToShow ? s.data.creatorDefaultScreen : screenToShow,
            isPanelCollapsed: s.data.currentScreenViewing === screenToShow,
            currentScreenLabel: ariaLabel,
          },
        }));
      }

      const screenEl = document.querySelector('.wt-screen');
      screenEl.focus();

      Helper.addDataLayer(get().data.userType, title, ariaLabel);
    },
    handleStartWalkthrough: e => {
      const { items, title } = useWalkthroughStore.getState().data;
      const { userType } = get().data;
      const ariaLabel = e.currentTarget.textContent;

      set(s => ({
        data: {
          ...s.data,
          isNavigatingWalkthrough: true,
          currentScreenViewing: 'Note',
          currentPageViewing: items ? items[0].link : href,
          defaultFramePage: items ? items[0].link : href,
        },
      }));

      if (ariaLabel) {
        Helper.addDataLayer(userType, title, ariaLabel);
      }
    },
    handleContinueCreateWalkthrough: link => {
      window.history.pushState({}, null, `${link}?context=edit`);
      set(s => ({
        data: {
          ...s.data,
          isNavigatingWalkthrough: true,
          currentScreenViewing: 'Note',
        },
      }));
    },
    handleContinueDuplicateWalkthrough: link => {
      window.history.pushState({}, null, `${link}?context=edit`);
      const { items } = useWalkthroughStore.getState().data;
      set(s => ({
        data: {
          ...s.data,
          currentScreenViewing: 'InfoScreen',
          currentPageViewing: items ? items[0].link : href,
          defaultFramePage: items ? items[0].link : href,

          // reset states (when duplicating template from Preview)
          isPanelCollapsed: false,
          isPreviewingTemplateMode: false,
          isDuplicatingTemplate: false,
          isViewingTemplate: false,
          userType: 1,
          creatorMode: 'edit',
        },
      }));

      if (get().data.isDuplicatingWalkthrough) {
        get().actions.handleDuplicatingWalkthrough();
      }
    },
    handleExitedWalkthrough: e => {
      const { title } = useWalkthroughStore.getState().data;
      const { userType } = get().data;
      const ariaLabel = e.currentTarget.textContent;

      if (ariaLabel) {
        Helper.addDataLayer(userType, title, ariaLabel);
      }

      window.location = `${origin}/walkthroughs`;
    },
    handleLeftWalkthrough: url => {
      set(s => ({
        data: {
          ...s.data,
          hasExitedWalkthrough: true,
          isNavigatingWalkthrough: false,
          currentPageViewing: url,
        },
      }));
    },
    handleReturnWalkthrough: () => {
      const { items } = useWalkthroughStore.getState().data;
      const lastLink = items[get().data.currentWalkthroughItemIndex].link;

      set(s => ({
        data: {
          ...s.data,
          hasExitedWalkthrough: false,
          isNavigatingWalkthrough: true,
          currentPageViewing: lastLink,
        },
      }));

      document.getElementById('wt-iframe').setAttribute('src', lastLink);
    },

    // MODAL ACTIONS
    handleExitingWalkthrough: e => {
      const { userType } = get().data;
      const { title } = useWalkthroughStore.getState().data;
      const btnText = e.currentTarget.textContent;

      set(s => ({
        data: { ...s.data, isExitingWalkthrough: !get().data.isExitingWalkthrough },
      }));

      if (btnText) {
        Helper.addDataLayer(userType, title, btnText);
      }

      handleModalToggle();
    },
    handleClosingWalkthrough: e => {
      const { userType } = get().data;
      const { title } = useWalkthroughStore.getState().data;
      const ariaLabel = e.currentTarget.getAttribute('aria-label');

      set(s => ({
        data: { ...s.data, isClosingWalkthrough: !get().data.isClosingWalkthrough },
      }));

      if (ariaLabel) {
        Helper.addDataLayer(userType, title, ariaLabel);
      }

      handleModalToggle();
    },
    handleDuplicatingWalkthrough: e => {
      const { userType } = get().data;
      const { title } = useWalkthroughStore.getState().data;
      const ariaLabel = e !== undefined && e.currentTarget.getAttribute('aria-label');

      set(s => ({
        data: { ...s.data, isDuplicatingWalkthrough: !get().data.isDuplicatingWalkthrough },
      }));

      if (ariaLabel) {
        Helper.addDataLayer(userType, title, ariaLabel);
      }

      handleModalToggle();
    },
    handleDeletingWalkthrough: e => {
      const { userType } = get().data;
      const { title } = useWalkthroughStore.getState().data;
      const ariaLabel = e.currentTarget.getAttribute('aria-label');

      set(s => ({
        data: { ...s.data, isDeletingWalkthrough: !get().data.isDeletingWalkthrough },
      }));

      if (ariaLabel) {
        Helper.addDataLayer(userType, title, ariaLabel);
      }

      handleModalToggle();
    },
    handleSharingWalkthrough: e => {
      const target = e.currentTarget || e.target;
      const { userType } = get().data;
      const { title } = useWalkthroughStore.getState().data;
      const ariaLabel = target.getAttribute('aria-label');

      set(s => ({
        data: { ...s.data, isSharingWalkthrough: !get().data.isSharingWalkthrough },
      }));

      if (ariaLabel) {
        Helper.addDataLayer(userType, title, ariaLabel);
      }

      handleModalToggle();
    },

    // FTUE
    handleShowTour: () => {
      set(s => ({
        data: { ...s.data, isViewingTour: true },
      }));

      handleModalToggle();
    },
    handleSkipTour: () => {
      set(s => ({
        data: { ...s.data, isSkippingTour: !get().data.isSkippingTour },
      }));
    },
    handleExitTour: () => {
      set(s => ({
        data: {
          ...s.data,
          isViewingTour: false,
          isSkippingTour: false,
        },
      }));

      handleModalToggle();
    },

    // PREVIEW
    handleStartPreview: () => {
      const { items } = useWalkthroughStore.getState().data;
      set(s => ({
        data: {
          ...s.data,
          isPreviewMode: true,
          userType: 2,
          creatorLastPageViewed: get().data.currentPageViewing,
          currentScreenViewing: get().data.clientDefaultScreen,
          currentPageViewing: items[0].link,
          defaultFramePage: items[0].link,
          currentWalkthroughItemIndex: 0,
          isNavigatingWalkthrough: false,
        },
      }));
    },
    handleExitPreview: () => {
      set(s => ({
        data: {
          ...s.data,
          isPreviewMode: false,
          userType: 1,
          currentScreenViewing: get().data.creatorDefaultScreen,
          currentPageViewing: get().data.creatorLastPageViewed,
          defaultFramePage: get().data.creatorLastPageViewed,
          isNavigatingWalkthrough: true,
        },
      }));
    },
    handleStartPreviewTemplate: () => {
      const { items } = useWalkthroughStore.getState().data;
      set(s => ({
        data: {
          ...s.data,
          isPanelCollapsed: true,
          currentScreenViewing: 'Note',
          currentPageViewing: items[0].link,
          defaultFramePage: items[0].link,
          currentWalkthroughItemIndex: 0,
          isNavigatingWalkthrough: true,
        },
      }));
    },
    handleExitPreviewTemplate: () => {
      window.location = window.dataLayer_content.templateHubUrl;
    },

    // PAGE NAVIGATION
    handlePrevPage: () => {
      const { items, title } = useWalkthroughStore.getState().data;
      const { userType } = get().data;

      if (get().data.currentWalkthroughItemIndex === 0) {
        return;
      }

      const index = get().data.currentWalkthroughItemIndex - 1;

      set(s => ({
        data: {
          ...s.data,
          isNavigatingWalkthrough: true,
          currentScreenViewing: 'Note',
          currentPageViewing: items[index].link,
          defaultFramePage: items[index].link,
          currentWalkthroughItemIndex: index,
          isPanelCollapsed: false,
        },
      }));

      Helper.addDataLayer(userType, title, 'Previous Page');
    },
    handleNextPage: () => {
      const { items, title } = useWalkthroughStore.getState().data;
      const { userType } = get().data;

      if (get().data.currentWalkthroughItemIndex === get().data.walkthroughItemsTotal - 1) {
        set(s => ({
          data: {
            ...s.data,
            currentScreenViewing: 'Conclusion',
            isNavigatingWalkthrough: false,
            isPanelCollapsed: false,
            hasFinishedWalkthrough: true,
          },
        }));

        return;
      }

      const index = get().data.currentWalkthroughItemIndex + 1;

      set(s => ({
        data: {
          ...s.data,
          isNavigatingWalkthrough: true,
          currentScreenViewing: 'Note',
          currentPageViewing: items[index].link,
          defaultFramePage: items[index].link,
          currentWalkthroughItemIndex: index,
          isPanelCollapsed: false,
        },
      }));

      Helper.addDataLayer(userType, title, 'Next Page');
    },
    handleBrowsePages: e => {
      const { items, title } = useWalkthroughStore.getState().data;
      const { userType } = get().data;
      const { link } = e.target.dataset;
      const index = items.findIndex(item => item.link === link);
      const ariaLabel = e.currentTarget.textContent;

      set(s => ({
        data: {
          ...s.data,
          currentPageViewing: link,
          defaultFramePage: link,
          currentWalkthroughItemIndex: index,
        },
      }));

      Helper.addDataLayer(userType, title, ariaLabel);
    },
    handleIframeLoadPage: iframe => {
      const { items } = useWalkthroughStore.getState().data;
      const link = iframe.location.href;
      const iFramePostID = iframe.dataLayer_content.postID;
      const index = items ? items.findIndex(item => item.link === link) : 0;

      if (
        // If Creator
        get().data.userType === 1
        // OR, If Client && not returning to walkthrough, set new data.
        || (get().data.userType === 2 && !get().data.hasExitedWalkthrough)
      ) {
        set(s => ({
          data: {
            ...s.data,
            currentWalkthroughItemIndex: index === -1 ? get().data.currentWalkthroughItemIndex : index,
            currentPageViewingID: iFramePostID,
            currentUserID: iframe.dataLayer_content.UserId,
          },
        }));

        if (get().data.currentPageViewing !== link) {
          set(s => ({
            data: {
              ...s.data,
              currentPageViewing: link,
              defaultFramePage: link,
            },
          }));
        }
      }
    },
    handleSetActiveElement: e => {
      set(s => ({
        data: {
          ...s.data,
          lastActiveMenuElement: e.currentTarget,
        },
      }));
    },
  },
})));

export default useStore;
