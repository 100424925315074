import React from 'react';

import useAppStateStore from '../../../../store/AppState.store';

import './PreviewBar.scss';

import LinkCTA from '../../atoms/Link/Link';

const PreviewBar = ({ mode, title }) => {
  const {
    data: { copy },
    actions: { handleExitPreview, handleExitPreviewTemplate, handleDuplicatingWalkthrough },
  } = useAppStateStore();
  const isTemplatePreview = mode === 'template';

  return (
    <div className={`preview-bar ${isTemplatePreview ? 'preview-bar--template' : ''}`}>
      <div className="preview-bar__container wt-container">
        <h2 className="wt-title-text wt-title-text--6">
          {!isTemplatePreview
            ? copy.screens.preview.title
            : (
              <>
                Previewing Template "{title}"{' '}
                <LinkCTA
                  label="Duplicate"
                  cta
                  onClick={handleDuplicatingWalkthrough}
                />
              </>
            )}
        </h2>

        <LinkCTA
          label={copy.screens.preview.exit_cta_label}
          cta
          onClick={!isTemplatePreview ? handleExitPreview : handleExitPreviewTemplate}
          classes="preview-bar__exit"
        />
      </div>
    </div>
  );
};

export default PreviewBar;
