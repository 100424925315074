import React from 'react';

import useAppStateStore from '../../../../../store/AppState.store';
import useWalkthroughStore from '../../../../../store/Walkthrough.store';

import './PanelHeader.scss';

import { formatDate } from '../../../../../utils/Helper';
import Icon from '../../../atoms/Icon/Icon';

const PanelHeader = ({ children }) => {
  const {
    data: { userType, isNavigatingWalkthrough, copy },
    actions: { handlePanelCollapse },
  } = useAppStateStore();
  const {
    data: { title, date, author_modified },
  } = useWalkthroughStore();

  const showDate = pos => (
    <div className={`wt-date wt-title-text wt-title-text--6 wt-date--${pos}`}>
      Last edited {formatDate(date, 'M/D')} by {author_modified.firstname} {(author_modified.lastname).charAt(0)}.
    </div>
  );

  return (
    <div className="wt-panel__header">
      <div className="wt-panel__header-overview" role="heading" aria-level="1">
        <div className="wt-eyebrow">{copy.brand}</div>
        <div className="wt-title">
          <strong dangerouslySetInnerHTML={{ __html: title || copy.screens.create.title }} />
        </div>
        {(userType === 1 && isNavigatingWalkthrough) && showDate('top')}
      </div>

      {isNavigatingWalkthrough && (
        <div className="wt-panel__header-action">
          <button aria-label="Collapse this panel" onClick={handlePanelCollapse}>
            <Icon type="close" />
          </button>
        </div>
      )}

      {children}
    </div>
  );
};

PanelHeader.propTypes = {};

PanelHeader.defaultProps = {};

export default PanelHeader;
