import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import './RTE.scss';

const RTE = ({
  id,
  value,
  placeholder,
  maxChar,
  classes,
  onChange,
  required,
}) => {
  const [textValue, setTextValue] = useState(value);
  const [hasMax, setHasMax] = useState(false);
  const [count, setCount] = useState(0);
  const [inputRef, setInputRef] = useState(null);

  const classNames = [
    'wt-form-input__control',
    'wt-form-input__control-textarea',
    'wt-form-input__control-textarea-rte',
    classes,
  ].join(' ');

  let timeout = null;

  const handleOnChange = (e, delta, source, editor) => {
    const editorCount = editor.getLength() - 1;

    if (timeout !== null) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(() => {
      setHasMax((required && editorCount === 0) || editorCount > maxChar);

      if (editorCount === 0) {
        onChange(undefined);
      } else {
        onChange(editor.getHTML());
      }

      setCount(editorCount);
      setTextValue(editor.getHTML());
    }, 100);
  };

  useEffect(() => {
    setTextValue(value);

    if (inputRef) {
      const el = inputRef.editor.root;
      const selection = window.getSelection();
      const range = document.createRange();

      // manually set new value
      el.innerHTML = (value !== undefined) ? value : '<p></p>';

      // manually set cursor
      selection.removeAllRanges();
      range.selectNodeContents(el.lastChild);
      range.collapse(false);
      selection.addRange(range);

      if (inputRef.unprivilegedEditor.getLength() - 1 < maxChar) {
        setCount(inputRef.unprivilegedEditor.getLength() - 1);
      }
    }

    // Set Aria-Label to Toolbar buttons
    const toolbar = document.querySelector('.ql-toolbar');
    if (toolbar) {
      const buttons = toolbar.querySelectorAll('button');

      buttons.forEach(button => {
        const classname = button.getAttribute('class').slice(3);
        button.setAttribute('aria-label', classname);
      });
    }
  }, [value]);

  // NOTE: editor.getLength() minus 1 because React Quill counts <br/> tag as 1 character

  return (
    <>
      <ReactQuill
        id={id}
        theme="snow"
        defaultValue={textValue || ''}
        onChange={handleOnChange}
        modules={{
          toolbar: [
            ['link'],
          ],
          keyboard: {
            bindings: { tab: false },
          },
        }}
        formats={[
          'link',
        ]}
        className={classNames}
        placeholder={placeholder}
        // ref={inputRef}
        ref={node => {
          if (node) {
            setInputRef(node);
          }
        }}
      />
      <div className="wt-text-counter">
        <span style={hasMax ? { color: 'red' } : null}>{count}</span>/{maxChar}
      </div>
    </>
  );
};

RTE.propTypes = {
  /* Built-in properties */
  placeholder: PropTypes.string.isRequired,
  required: PropTypes.bool,

  /* Custom Classes */
  classes: PropTypes.string,
};

RTE.defaultProps = {
  classes: '',
  required: false,
};

export default RTE;
