/* eslint-disable max-len */
/* eslint-disable no-return-assign */
/* eslint-disable no-new-func */
import React from 'react';

import useAppStateStore from '../../../../store/AppState.store';
import useWalkthroughStore from '../../../../store/Walkthrough.store';
import * as Helper from '../../../../utils/Helper';
import * as menuItems from '../../../../utils/Toolbar.menu';
import MenuMapping from '../../../../utils/Toolbar.menu.map';

import './Toolbar.scss';

import Icon from '../../atoms/Icon/Icon';
import Counter from '../../atoms/Counter/Counter';
import LinkCTA from '../../atoms/Link/Link';

const Toolbar = () => {
  const {
    data: {
      userType,
      isNavigatingWalkthrough,
      currentPageViewing,
      currentScreenViewing,
      currentWalkthroughItemIndex,
      walkthroughItemsTotal,
      hasExitedWalkthrough,
      copy,
    },
  } = useAppStateStore.getState();
  const {
    data: { items, appendix, type, poc_group },
  } = useWalkthroughStore.getState();
  const actions = { ...useAppStateStore.getState().actions, ...useWalkthroughStore.getState().actions };
  const menuLabel = userType === 1 ? copy.menu_labels.creator : copy.menu_labels.client;

  const handleHideMenuItems = menu => {
    let hideMe = false;

    if (
      // Hide Edit Order if page items is empty.
      (menu.id === 'edit-order' && items === null)
      // Hide Add Page if viewing page already added.
      || (menu.id === 'add-page' && Helper.isValidURL(items, Helper.cleanLocationURL(currentPageViewing)))

      // Hide Remove Page if viewing page not yet added.
      || (menu.id === 'remove-page' && !Helper.isValidURL(items, Helper.cleanLocationURL(currentPageViewing)))

      // Hide Add Note if viewing page not yet added.
      || (menu.id === 'add-note' && !Helper.isValidURL(items, Helper.cleanLocationURL(currentPageViewing)))

      // Hide Contact if previewing a template
      || (menu.id === 'contact' && type === 'presentation_template')

      // Hide Contact if no POC added yet
      || (menu.id === 'contact' && poc_group === null)
    ) {
      hideMe = true;
    }

    if (menu.id === 'add-appendix') {
      if (currentScreenViewing === 'Appendix') {
        if (Helper.isValidURL(appendix, Helper.cleanLocationURL(currentPageViewing))) {
          hideMe = true;
        } else {
          hideMe = false;
        }
      } else {
        hideMe = true;
      }
    }

    if (menu.id === 'remove-appendix') {
      if (currentScreenViewing === 'Appendix') {
        if (appendix !== null) {
          if (Helper.isValidURL(appendix, Helper.cleanLocationURL(currentPageViewing))) {
            hideMe = false;
          } else {
            hideMe = true;
          }
        } else {
          hideMe = true;
        }
      } else {
        hideMe = true;
      }
    }

    return hideMe;
  };

  const handleGenerateList = menuItems => {
    return (
      <ul className="toolbar__menu-list">
        {menuItems.map((menuItem, i) => {
          const showLabel = menuItem.showHoverLabel !== undefined ? menuItem.showHoverLabel : true;
          const total = items ? items.length : 0;

          const isDisabled = (menuItem.id === 'page-prev' && currentWalkthroughItemIndex === 0)
            || (menuItem.id === 'page-next' && items.length === 1 && poc_group === null)
            || (menuItem.id === 'page-next' && currentWalkthroughItemIndex + 1 === items.length && poc_group === null)
            || (menuItem.id === 'add-page' && currentScreenViewing === 'Appendix');
          const isActive = menuItem.screenToShow === currentScreenViewing;

          const classes = [
            isActive ? 'menu-is-active' : '',
            isDisabled ? 'menu-is-disabled' : '',
          ].join(' ');

          const handleClick = e => {
            actions.handleSetActiveElement(e);
            actions[menuItem.eventHandler](e);
          };

          // Check if menu items is to be hidden
          // If return value is true, then do not render.
          if (handleHideMenuItems(menuItem)) {
            return false;
          }

          return (
            <li key={i} className={classes}>
              <button
                className={`toolbar__menu-btn ${
                  !showLabel ? 'btn-no-label' : ''
                }`}
                aria-label={menuLabel[MenuMapping[menuItem.id]]}
                data-screen={menuItem.screenToShow}
                onClick={handleClick}
                aria-disabled={isDisabled}
                aria-pressed={isActive}
              >
                <Icon type={menuItem.icon} />
                {(menuItem.id === 'edit-order' && total !== 0) && (
                  <span>{total}</span>
                )}
              </button>
            </li>
          );
        })}
      </ul>
    );
  };

  return (
    <div className="toolbar">
      <div className="toolbar__menu toolbar__menu--top">
        {/* Show this menu, before and after the Walkthrough session */}
        {!isNavigatingWalkthrough && handleGenerateList(menuItems.exit_task)}

        {/* Show this Creator menu, during the Walkthrough session */}
        {(isNavigatingWalkthrough && userType === 1) && (
          <div className="creator-tasks">
            {handleGenerateList(menuItems.creator_tasks_1)}
          </div>
        )}

        {/* Show this Client menu, during the Walkthrough session */}
        {isNavigatingWalkthrough && userType === 2 && (
          <div className="client-tasks">
            <Counter classes="client-tasks__counter" current={currentWalkthroughItemIndex + 1} total={walkthroughItemsTotal} />
            {handleGenerateList(menuItems.client_tasks_1)}
          </div>
        )}
      </div>

      <div className="toolbar__menu toolbar__menu--bottom">
        {/* Show this Creator menu, during the Walkthrough session */}
        {isNavigatingWalkthrough && userType === 1 && (
          handleGenerateList(menuItems.creator_tasks_2)
        )}

        {/* Show this Client menu, during the Walkthrough session */}
        {isNavigatingWalkthrough && userType === 2 && (
          handleGenerateList(menuItems.client_tasks_2)
        )}
      </div>

      {hasExitedWalkthrough && (
        <>
          <div className="toolbar__menu toolbar__menu--return">
            {handleGenerateList(menuItems.return_task)}
          </div>
          <div className="toolbar__menu toolbar__menu--return-bar">
            <LinkCTA
              label={menuLabel.return}
              cta
              onClick={actions.handleReturnWalkthrough}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default Toolbar;
