/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';

import useAppStateStore from '../../../../../store/AppState.store';
import useWalkthroughStore from '../../../../../store/Walkthrough.store';

import './Conclusion.scss';

import Screen, { ScreenBody } from '../../../atoms/Screen/Screen';
import CardContact from '../../../atoms/CardContact/CardContact';
import LinkCTA from '../../../atoms/Link/Link';

const Conclusion = () => {
  const { poc_group } = useWalkthroughStore().data;
  const {
    data: { copy },
    actions: { handleExitingWalkthrough },
  } = useAppStateStore();
  const conclusionLabel = copy.screens.conclusion;
  const [activePOC, setActivePOC] = useState(null);

  useEffect(() => {
    const { search } = window.location;
    const urlParams = new URLSearchParams(search);
    let pocIndex;

    // Check for Active POC ID in URL params
    // Else get in window.dataLayer_content
    if (urlParams.has('poc')) {
      pocIndex = urlParams.get('poc');
    } else {
      pocIndex = window.dataLayer_content.activePOC;
    }

    // Check if Active POC ID is existing
    // Else, set first item in POC_group data array
    if (pocIndex) {
      const active = poc_group.find(poc => poc.point_of_contact.id === parseInt(pocIndex));

      if (active) {
        setActivePOC(active);
      } else {
        setActivePOC(poc_group[0]);
      }
    }
  }, [poc_group]);

  return (
    <Screen title={conclusionLabel.title} classes="wt-screen--next">
      <ScreenBody>
        <div className="wt-conclusion">
          {activePOC && (
            <p dangerouslySetInnerHTML={{ __html: activePOC.conclusion || conclusionLabel.conclusion_field_default_value }} />
          )}

          <p dangerouslySetInnerHTML={{ __html: conclusionLabel.conclusion_field_profile_link }} />

          {activePOC && (
            <CardContact contact={activePOC.point_of_contact} />
          )}

          <div style={{ textAlign: 'center', marginTop: '20px' }}>
            <LinkCTA
              label="or simply close this FOX Selects"
              cta
              onClick={handleExitingWalkthrough}
            />
          </div>
        </div>
      </ScreenBody>
    </Screen>
  );
};

export default Conclusion;
