/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
import React, { lazy, Suspense } from 'react';

import useAppStateStore from '../../../../store/AppState.store';
import useWalkthroughStore from '../../../../store/Walkthrough.store';
import ErrorBoundary from '../../../../utils/ErrorBoundary';

import './Panel.scss';

import PanelHeader from './PanelHeader/PanelHeader';
import PanelToggle from './PanelToggle/PanelToggle';
import Toolbar from '../Toolbar/Toolbar';
import Existing from '../Screens/Existing/Existing';
import Appendix from '../Screens/Appendix/Appendix';
import Logo from '../../atoms/Logo/Logo';
import Icon from '../../atoms/Icon/Icon';
import Counter from '../../atoms/Counter/Counter';

const Panel = () => {
  const {
    data: {
      isPanelCollapsed,
      isNavigatingWalkthrough,
      hasFinishedWalkthrough,
      hasExitedWalkthrough,
      currentScreenViewing,
      currentWalkthroughItemIndex,
      walkthroughItemsTotal,
      userType,
      copy,
    },
    actions: {
      handleExitingWalkthrough,
    },
  } = useAppStateStore();
  const { existing_title, appendix_title } = copy.screens.other;
  const { existingList, appendix } = useWalkthroughStore().data;

  const DynamicComponent = lazy(async () => await require(`../Screens/${currentScreenViewing}/${currentScreenViewing}`));

  const isCreatorStartingWT = userType === 1 && currentScreenViewing === 'InfoScreen' && !isNavigatingWalkthrough;
  const hasClientFinishedWT = userType === 2 && currentScreenViewing === 'Conclusion' && hasFinishedWalkthrough;
  const showExistingScreen = isCreatorStartingWT && (existingList !== null && existingList.length !== 0);
  const showAppendixScreen = hasClientFinishedWT && appendix !== null;

  const className = [
    'wt-panel',
    `wt-panel--${currentScreenViewing}`,
    isPanelCollapsed ? 'is-collapsed' : '',
    hasExitedWalkthrough ? 'is-exited' : '',
    (showExistingScreen || showAppendixScreen) ? 'has-panel-toggle' : '',
  ].join(' ');

  return (
    <>
      <div className={className} role="region" aria-label="Walkthrough Widget Panel">
        <div className="wt-panel__mobile-closer">
          <div className="wt-panel__logo"><Logo theme="light" /></div>
          <div className="wt-panel__mobile-closer-action">
            <button aria-label="Collapse this panel" onClick={handleExitingWalkthrough}>
              <Icon type="close" />
            </button>
          </div>
        </div>

        <section className="wt-panel__body" aria-hidden={isPanelCollapsed}>
          <PanelHeader>
            {userType === 2 && (
              <Counter
                classes="wt-panel__counter"
                current={currentWalkthroughItemIndex + 1}
                total={walkthroughItemsTotal}
              />
            )}
          </PanelHeader>

          <ErrorBoundary
            fallback={<div>Stop trying to make fetch happen. It's not gonna happen.</div>}
          >
            <div className="wt-panel__main">
              <div className="wt-panel__main-wrapper">
                <Suspense fallback={<div aria-hidden="true">Loading...</div>}>
                  <DynamicComponent />
                </Suspense>
              </div>
            </div>
          </ErrorBoundary>

          {/* Show Existing List component if  */}
          {/* - userType === 1, Creator */}
          {/* - currentScreenViewing === Info */}
          {/* - isNavigatingWalkthrough === false */}
          {showExistingScreen && (
            <PanelToggle title={existing_title}>
              <Existing />
            </PanelToggle>
          )}

          {/* Show Appendix List component if  */}
          {/* - userType === 2, Client */}
          {/* - currentScreenViewing === Conclusion */}
          {/* - isNavigatingWalkthrough === false (finished WT) */}
          {showAppendixScreen && (
            <PanelToggle title={appendix_title}>
              <Appendix />
            </PanelToggle>
          )}
        </section>

        <section className="wt-panel__toolbar">
          <Toolbar />
        </section>
      </div>
    </>
  );
};

export default Panel;
