import React from 'react';
import PropTypes from 'prop-types';

import './Link.scss';

const Link = ({
  label,
  children,
  url,
  theme = 'dark',
  classes,
  external = false,
  cta = false,
  onClick,
}) => {
  const className = [
    'link',
    classes,
    theme !== 'dark' ? 'link--on-white' : '',
  ].join(' ');

  if (cta) {
    return (
      <button type="button" className={className} onClick={onClick}>
        {label || children}
      </button>
    );
  }

  if (external) {
    return (
      <a
        className={className}
        href={url}
        target="_blank"
        rel="noopener noreferrer"
      >
        {label}
      </a>
    );
  }

  return (
    <a className={className} href={url}>
      {label}
    </a>
  );
};

Link.propTypes = {
  label: PropTypes.string.isRequired,
  url: PropTypes.string,
  classes: PropTypes.string,
  cta: PropTypes.bool,
  external: PropTypes.bool,
  onClick: PropTypes.func,
};

Link.defaultProps = {
  classes: '',
  url: '',
  cta: false,
  external: false,
  onClick: null,
};

export default Link;
