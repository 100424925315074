/* eslint-disable max-len */
import React from 'react';

import './FTUE_Steps.scss';

import Slider from '../../Slides/Slides';

const FTUE_Steps = ({ copy, onStart, steps }) => {
  return (
    <div className="wt-ftue-steps">
      <Slider slideData={steps} onStart={onStart} copy={copy} />
    </div>
  );
};

export default FTUE_Steps;
