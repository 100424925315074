import React, { useLayoutEffect, useState, useRef } from 'react';
import shallow from 'zustand/shallow';

import * as Helper from '../../../../utils/Helper';
import ErrorBoundary from '../../../../utils/ErrorBoundary';

import useAppStateStore from '../../../../store/AppState.store';
import useWalkthroughStore from '../../../../store/Walkthrough.store';

import './App.scss';

import Panel from '../Panel/Panel';
import FTUE from '../FTUE/FTUE';
import PreviewBar from '../PreviewBar/PreviewBar';
import ExitModal from '../Modals/ExitModal';
import SkipModal from '../Modals/SkipModal';
import DeleteModal from '../Modals/DeleteModal';
import CloseModal from '../Modals/CloseModal';
import DuplicateModal from '../Modals/DuplicateModal';
import ShareModal from '../Modals/ShareModal';

const getAppState = state => (
  {
    data: state.data,
    actions: state.actions,
  }
);

const Layout = () => {
  const [isLoading, setIsLoading] = useState(true);
  const {
    data: {
      isSharingOnly,
      userType,
      isPreviewMode,
      isPreviewingTemplateMode,
      isViewingTemplate,
      isNavigatingWalkthrough,
      isViewingTour,
      isSkippingTour,
      isExitingWalkthrough,
      isDeletingWalkthrough,
      isSharingWalkthrough,
      isDuplicatingWalkthrough,
      isDuplicatingTemplate,
      isClosingWalkthrough,
      hasExitedWalkthrough,
      defaultFramePage,
    },
    actions: {
      handleGetDictionaryApi,
      handleGetWalkthroughApi,
      handleGetWalkthroughEditApi,
      handleGetWalkthroughListApi,
      handleGetUsersApi,
      handleSetCopy,
      handleSetUsers,
      handleSetInitialScreen,
      handleLeftWalkthrough,
      handleIframeLoadPage,
      handleShowTour,
      handleStartPreviewTemplate,
    },
  } = useAppStateStore(getAppState, shallow);
  const {
    data: { title, items, appendix },
    actions: { handleSetInitialData, handleExistingWalkthroughList, handleDuplicateTemplate },
  } = useWalkthroughStore();

  const iframeRef = useRef(null);
  let intervalId = null;

  const className = [
    'wt-app',
    !isNavigatingWalkthrough ? 'wt-app--float' : '',
    hasExitedWalkthrough ? 'wt-app--left' : '',
    (isPreviewMode && isPreviewingTemplateMode) ? 'wt-app--preview' : '',
  ].join(' ');

  const iframeOnLoad = async () => {
    // Run only for Client
    // TODO: THIS INtERVAL IS PROBABLY NOT NEEDED
    if (userType === 2) {
      if (intervalId) {
        clearInterval(intervalId);
      }

      intervalId = setInterval(checkIframeHasLeftExperience, 1000);
    }

    await handleIframeLoadPage(iframeRef.current.contentWindow);
  };

  const checkIframeHasLeftExperience = () => {
    const validURLs = [...items || [], ...(appendix || [])];

    // Get latest hasExitedWalkthrough value,
    // If hasExitedWalkthrough is true,
    // clear interval and stop checking
    if (useAppStateStore.getState().data.hasExitedWalkthrough) {
      clearInterval(intervalId);
      return false;
    }

    // Else, keep checking if user click outside valid URLs
    try {
      if (iframeRef.current) {
        const url = iframeRef.current.contentWindow.location.href;
        const isValidURL = Helper.isValidURL(validURLs, url);

        if (!isValidURL) {
          handleLeftWalkthrough(url);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  useLayoutEffect(async () => {
    const id = window.dataLayer_content
      ? window.dataLayer_content.postID
      : process.env.REACT_APP_POST_ID;

    const fetchCalls = [
      handleGetDictionaryApi(),
    ];

    // If user is a Creator
    if (userType === 1) {
      fetchCalls.push(
        handleGetUsersApi(),
        handleGetWalkthroughListApi(),
      );

      if (id) {
        fetchCalls.push(handleGetWalkthroughEditApi(id));
      }
    }

    // If user is a Client
    if (userType !== 1) {
      if (isViewingTemplate) {
        fetchCalls.push(
          handleGetUsersApi(),
          handleGetWalkthroughEditApi(id),
        );
      } else {
        fetchCalls.push(handleGetWalkthroughApi(id));
      }
    }

    await Promise.allSettled(fetchCalls)
      .then(res => {
        if (res[0].status === 'fulfilled') {
          handleSetCopy(res[0].value);
        }

        if (userType === 1) {
          if (res[1].status === 'fulfilled') {
            handleSetUsers(res[1].value);
          }
          if (res[2].status === 'fulfilled') {
            handleExistingWalkthroughList(res[2].value);
          }

          if (id && res[3].status === 'fulfilled') {
            handleSetInitialData(res[3].value);
          }
        }

        if (userType !== 1 && isViewingTemplate) {
          if (res[1].status === 'fulfilled') {
            handleSetUsers(res[1].value);
          }

          if (id && res[2].status === 'fulfilled') {
            handleSetInitialData(res[2].value);
          }
        }

        if (userType !== 1 && !isViewingTemplate) {
          if (res[1].status === 'fulfilled') {
            handleSetInitialData(res[1].value);
          }
        }
      }).then(() => {
        handleSetInitialScreen();

        if (isViewingTemplate) {
          handleStartPreviewTemplate();
        }

        if (isDuplicatingTemplate) {
          handleDuplicateTemplate();
        }
      }).finally(() => {
        setIsLoading(false);
      });

    return () => clearInterval(intervalId);
  }, []);

  useLayoutEffect(() => {
    if (!isSharingOnly) {
      const wt_ftue_count = localStorage.getItem('wt_ftue_count');

      // Check the number of times the FTUE was shown.
      // If not present, set first count.
      if (wt_ftue_count === null || wt_ftue_count === undefined) {
        localStorage.setItem('wt_ftue_count', 1);
        showFTUE();
        return false;
      }

      // Else, increment count if not === 3
      if (wt_ftue_count && wt_ftue_count < 3) {
        localStorage.setItem('wt_ftue_count', parseInt(wt_ftue_count) + 1);
        showFTUE();
      }
    }

    function showFTUE() {
      handleShowTour();
    }
  }, []);

  return (
    <div className={className}>
      {isLoading ? (
        <div
          style={{
            display: 'flex',
            width: '100%',
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {isDuplicatingTemplate && 'Duplicating...'}
          {isPreviewingTemplateMode && 'Loading Preview...'}
          {!isViewingTemplate && 'Loading...'}
        </div>
      ) : (
        <>
          {!isViewingTour && (
            <>
              {(isPreviewMode || isPreviewingTemplateMode) && (
                <div className="wt-app__bar">
                  <PreviewBar
                    mode={isPreviewingTemplateMode ? 'template' : null}
                    title={isPreviewingTemplateMode ? title : null}
                  />
                </div>
              )}
              <div className="wt-app__content">
                <div className="wt-iframe" role="region" aria-label="Walkthrough iFrame Panel">
                  <iframe
                    id="wt-iframe"
                    src={defaultFramePage}
                    ref={iframeRef}
                    title="Fox Ads Solution"
                    frameBorder="0"
                    onLoad={iframeOnLoad}
                  />
                </div>
                <ErrorBoundary>
                  <Panel />
                </ErrorBoundary>
              </div>
            </>
          )}

          {/* MODALS */}
          {/* TODO: Lazy Load these modals */}
          {isViewingTour && <FTUE isOpen={isViewingTour} />}
          {isSkippingTour && <SkipModal isOpen={isSkippingTour} />}
          {isExitingWalkthrough && <ExitModal isOpen={isExitingWalkthrough} />}
          {isClosingWalkthrough && <CloseModal isOpen={isClosingWalkthrough} />}
          {isSharingWalkthrough && <ShareModal isOpen={isSharingWalkthrough} />}
          {isDuplicatingWalkthrough && <DuplicateModal isOpen={isDuplicatingWalkthrough} />}
          {isDeletingWalkthrough && <DeleteModal isOpen={isDeletingWalkthrough} />}
        </>
      )}
    </div>
  );
};

Layout.propTypes = {};

export default Layout;
