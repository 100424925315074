import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import useWalkthroughStore from '../../../../../../store/Walkthrough.store';

import './EditorName.scss';
import '../FormInput.scss';

import Label from '../../Controls/Label/Label';
import Autocomplete from '../../Controls/Autocomplete/Autocomplete';

import Icon from '../../../Icon/Icon';

const EditorNameField = ({
  field,
  label,
  value,
  options,
  required,
  onChange,
  classes,
}) => {
  const { title } = useWalkthroughStore.getState().data;
  const [isAddingEditor, setIsAddingEditor] = useState(false);
  const [addedEditors, setAddedEditors] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [hasMaxSelected, setMaxSelected] = useState(false);
  const maxSelection = 5;

  const classNames = [
    'wt-form-input',
    'wt-form-input-autocomplete',
    'wt-form-input-autocomplete--multiple',
    hasError ? 'has-error' : '',
    classes,
  ].join(' ');

  const handleChange = val => {
    let newValue;
    const newEditors = addedEditors;

    if (val !== undefined) {
      newValue = {
        id: val.ID,
        email: val.email,
        firstname: val.first_name,
        lastname: val.last_name,
      };
      newEditors.push(newValue);

      setAddedEditors(newEditors);
      onChange(field, newEditors);
      setIsAddingEditor(false);
      setMaxSelected(newEditors.length === maxSelection);

      window.dataLayer.push({
        'event': 'walkthrough',
        'eventType': 'add editor click',
        'walkthroughTitle': title,
        'selectedUserId': val.ID,
      });
    } else {
      onChange(field, undefined);
    }
  };

  const handleToggleAddEditor = () => {
    setIsAddingEditor(!isAddingEditor);
  };

  const handleRemoveEditor = id => {
    const filteredEditors = addedEditors.filter(editor => editor.id !== id);
    setAddedEditors(filteredEditors);
    setMaxSelected(filteredEditors.length === maxSelection);
    setHasError(required && filteredEditors.length === 0);
    onChange(field, filteredEditors.length === 0 ? undefined : filteredEditors);
    window.dataLayer.push({
      'event': 'walkthrough',
      'eventType': 'remove editor click',
      'walkthroughTitle': title,
      'selectedUserId': id,
    });
  };

  const getSelectedOptions = () => {
    const selected = [];
    addedEditors.forEach(editor => selected.push(editor.id));
    return selected;
  };

  useEffect(() => {
    if (value) {
      setAddedEditors(value);
    }
  }, []);

  return (
    <div className={classNames}>
      <Label field={field} text={label} />
      <div className={`wt-form-input__control wt-form-input__control-pseudo ${isAddingEditor ? 'is-control-active' : ''}`}>
        <button
          aria-label="Add new editor"
          onClick={!hasMaxSelected ? handleToggleAddEditor : null}
          style={hasMaxSelected ? { 'opacity': '0.5' } : null}
        >
          <Icon type={!isAddingEditor ? 'add-square' : 'remove-square'} />
        </button>
      </div>

      {isAddingEditor && (
        <Autocomplete
          type="multiple"
          name={field}
          value={value}
          required={required}
          options={options}
          onChange={handleChange}
          searchBy="name"
          selectedOptions={getSelectedOptions()}
          maxSelection={5}
        />
      )}

      {addedEditors.length > 0 && (
        <ul className="selections">
          {addedEditors.map(editor => {
            return (
              <li key={editor.id}>
                <button aria-label="Remove editor" onClick={() => handleRemoveEditor(editor.id)}>
                  <Icon type="remove-square" />
                </button>
                <span>{editor.firstname} {editor.lastname}</span>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

EditorNameField.propTypes = {
  required: PropTypes.bool,
  field: PropTypes.string,
  label: PropTypes.string,
  classes: PropTypes.string,
  options: PropTypes.instanceOf(Array).isRequired,
};

EditorNameField.defaultProps = {
  required: false,
  field: 'author',
  label: '',
  classes: '',
};

export default EditorNameField;
