import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../../../Icon/Icon';

import './SelectionList.scss';

const SelectionList = ({ list, emptyLabel, onRemove }) => {
  const handleRemove = id => {
    onRemove(id);
  };

  return (
    <ul className="wt-selection-list">
      {(list && list.length > 0) ? (
        list.map(list => {
          return (
            <li key={list.id}>
              <button aria-label="Remove editor" onClick={() => handleRemove(list.id)}>
                <Icon type="remove-square" />
              </button>
              <span>{list.firstname} {list.lastname}</span>
            </li>
          );
        })
      ) : (
        <li>{emptyLabel}</li>
      )}
    </ul>
  );
};

SelectionList.propTypes = {

};

export default SelectionList;
