import React, { useState, useEffect, useRef } from 'react';

import useAppStateStore from '../../../../../store/AppState.store';
import useWalkthroughStore from '../../../../../store/Walkthrough.store';

import './InfoScreen.scss';

import Screen, { ScreenBody, ScreenFooter } from '../../../atoms/Screen/Screen';
import TitleField from '../../../atoms/Form/Fields/Title/Title';
import IntroductionField from '../../../atoms/Form/Fields/Introduction/Introduction';
import AuthorField from '../../../atoms/Form/Fields/Author/Author';
import EditorField from '../../../atoms/Form/Fields/EditorName/EditorName';
import Button from '../../../atoms/Button/Button';
import LinkCTA from '../../../atoms/Link/Link';

const InfoScreen = () => {
  const {
    data: { isNavigatingWalkthrough, copy, users, creatorMode, currentPageViewingID, currentUserID },
    actions: { handleShowTour },
  } = useAppStateStore();
  const {
    data,
    actions: { handleSave },
  } = useWalkthroughStore();
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [formData, setFormData] = useState({});
  const [options, setOptions] = useState([]);
  const [hasError, setHasError] = useState(false);
  const createLabel = copy.screens.create;
  const submitBtnRef = useRef(null);

  const handleChange = (field, newValue) => {
    setHasError(false);

    setFormData({
      ...formData,
      [field]: newValue,
    });
  };

  const handleSubmit = async () => {
    // prepare / validate data
    const formValues = Object.values(formData);

    if (formValues.includes(undefined)) {
      return false;
    }

    setIsSaving(true);

    if (creatorMode === 'add') {
      // call state action
      const res = await handleSave({
        ...data,
        ...formData,
        status: 'publish',
        items: [{
          id: currentPageViewingID,
        }],
      });

      if (res) {
        const { data } = res;
        if (data) {
          const { status } = data;
          if (status === 400) {
            setHasError(true);
          }
        }

        setIsSaving(false);
      }
    } else {
      const res = await handleSave(formData);

      if (res) {
        const { data } = res;
        if (data) {
          const { status } = data;
          if (status === 400) {
            setHasError(true);
          }
        }

        setIsSaving(false);
      }
    }
  };

  useEffect(async () => {
    if (creatorMode !== null) {
      setOptions(users.authors);

      const loggedUser = users.authors.find(user => user.ID === currentUserID);
      let loggedAuthor;

      if (loggedUser !== undefined) {
        loggedAuthor = {
          id: loggedUser.ID,
          email: loggedUser.email,
          firstname: loggedUser.first_name,
          lastname: loggedUser.last_name,
        };
      }

      setFormData({
        title: data.title,
        description: data.description,
        author: creatorMode === 'add' ? loggedAuthor : data.author,
        editors: data.editors,
      });
    }

    setIsLoading(false);
  }, []);

  if (isLoading) {
    return (
      <div
        style={{
          display: 'flex',
          width: '100%',
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        Loading...
      </div>
    );
  }

  // const showDate = pos => (
  //   <div className={`wt-date wt-title-text wt-title-text--6 wt-date--${pos}`}>{formatDate(data.date)}</div>
  // );

  return (
    <>
      <Screen classes="wt-screen--info">
        <ScreenBody>
          <div className="wt-info">
            <div className="wt-info__form">
              <TitleField
                field="title"
                value={formData.title}
                label={createLabel.title_field_label}
                onChange={handleChange}
                required
                hasError={hasError}
              />
              <IntroductionField
                field="description"
                value={formData.description}
                placeholder={createLabel.intro_field_label}
                onChange={handleChange}
                required
              />
              <AuthorField
                field="author"
                value={formData.author}
                label={createLabel.author_field_label}
                onChange={handleChange}
                required
                hasError={hasError}
              />
              <EditorField
                field="editors"
                value={formData.editors}
                label={createLabel.editor_field_label}
                options={options}
                onChange={handleChange}
                required
              />

              {!isNavigatingWalkthrough && (
                <>
                  <div className="wt-form-input">
                    <Button
                      label={!isSaving ? createLabel.start_field_label : 'Saving'}
                      classes="cta-btn"
                      onClick={handleSubmit}
                      ref={submitBtnRef}
                      disabled={Object.values(formData).includes(undefined)}
                    />
                  </div>
                  <div className="wt-form-input" style={{ textAlign: 'center' }}>
                    <LinkCTA
                      label={createLabel.review_cta_label}
                      cta
                      onClick={handleShowTour}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </ScreenBody>

        {isNavigatingWalkthrough && (
          <ScreenFooter>
            <div className="wt-form-input">
              <Button
                label={!isSaving ? createLabel.submit_cta_label : 'Saving'}
                classes="cta-btn"
                onClick={handleSubmit}
                ref={submitBtnRef}
                disabled={Object.values(formData).includes(undefined)}
              />
            </div>
          </ScreenFooter>
        )}
      </Screen>
    </>
  );
};

export default InfoScreen;
