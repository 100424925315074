/* eslint-disable max-len */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import './Conclusion.scss';
import '../FormInput.scss';

import Label from '../../Controls/Label/Label';
import RTE from '../../Controls/RTE/RTE';

const ConclusionField = ({
  id,
  field,
  value,
  placeholder,
  maxChar,
  required,
  classes,
  onChange,
}) => {
  const classNames = [
    'wt-form-input',
    'wt-form-input--textarea',
    classes,
  ].join(' ');
  const conclusionRef = useRef(null);

  const [newValue, setNewValue] = useState(value);

  const handleChange = val => {
    onChange(val);
  };

  useEffect(() => {
    setNewValue(value !== null ? value : undefined);

    if (conclusionRef) {
      conclusionRef.current.querySelector('.ql-editor').focus();
    }
  }, [value]);

  return (
    <div className={classNames} ref={conclusionRef}>
      <Label field={field} text="Walkthrough Conclusion" hidden="true" />
      <RTE
        id={id}
        name={field}
        value={newValue}
        placeholder={placeholder}
        maxChar={maxChar}
        required={required}
        onChange={handleChange}
      />
    </div>
  );
};

ConclusionField.propTypes = {
  required: PropTypes.bool,
  field: PropTypes.string,
  classes: PropTypes.string,
  placeholder: PropTypes.string,
  maxChar: PropTypes.string,
};

ConclusionField.defaultProps = {
  required: false,
  field: 'conclusion',
  classes: '',
  placeholder:
    'To sum it up, here is a comprehensive list of all FOX NEXT can do for you. Let me know if you saw something of interest to you and I’m happy to jump on the phone and talk next steps.',
  maxChar: '240',
};

export default ConclusionField;
