/* eslint-disable react/jsx-pascal-case */
import React, { useState } from 'react';

import useAppStateStore from '../../../../store/AppState.store';

import Modal from '../../atoms/Modal/Modal';
import FTUE_Landing from './FTUE_Landing/FTUE_Landing';
import FTUE_Steps from './FTUE_Steps/FTUE_Steps';

const FTUE = ({ isOpen }) => {
  const [showSteps, setShowSteps] = useState(false);
  const {
    data: { userType, copy },
    actions: { handleSkipTour, handleExitTour },
  } = useAppStateStore();

  const tourLabel = userType === 1 ? copy.ftue.creator : copy.ftue.client;
  const stepsData = userType === 1 ? copy.ftue.creator.steps : copy.ftue.client.steps;

  const handleShowSteps = () => {
    setShowSteps(true);
  };

  return (
    <div className="wt-ftue">
      <Modal isModalOpen={isOpen} onClose={handleSkipTour}>
        {!showSteps && (
          <FTUE_Landing copy={tourLabel} onSkip={handleSkipTour} onContinue={handleShowSteps} />
        )}
        {showSteps && (
          <FTUE_Steps copy={tourLabel} onStart={handleExitTour} steps={stepsData} />
        )}
      </Modal>
    </div>
  );
};

export default FTUE;
