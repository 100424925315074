import React from 'react';
// import PropTypes from 'prop-types';

import useAppStateStore from '../../../../store/AppState.store';

import Modal from '../../atoms/Modal/ConfirmModal';
import Button from '../../atoms/Button/Button';
import Heading from '../../atoms/Heading/Heading';
import Text from '../../atoms/Text/Text';

const ExitModal = ({ isOpen }) => {
  const {
    data: { userType, copy },
    actions: { handleExitingWalkthrough, handleExitedWalkthrough },
  } = useAppStateStore();
  const confirmLabel = userType === 1 ? copy.confirmation.exit.creator : copy.confirmation.exit.client;

  return (
    <div className="modal-confirm modal-confirm--exit">
      <Modal isModalOpen={isOpen}>
        <Heading level="2" classes="wt-title-text--3 wt-confirm-modal__confirm-heading">
          {confirmLabel.title}
        </Heading>
        <Text tag="p" classes="wt-confirm-modal__description">
          {confirmLabel.description}
        </Text>
        <div className="wt-confirm-modal__controls">
          <Button
            theme="light"
            classes="wt-confirm-modal__cta-1"
            onClick={handleExitedWalkthrough}
            label={confirmLabel.cta_confirm}
          />
          <Button
            theme="light"
            classes="wt-confirm-modal__cta-2"
            onClick={handleExitingWalkthrough}
            label={confirmLabel.cta_deny}
          />
        </div>
      </Modal>
    </div>
  );
};

// ExitModal.propTypes = {};

export default ExitModal;
