const MenuMapping = {
  'edit-info': 'info',
  'edit-order': 'pages',
  'add-page': 'add_page',
  'remove-page': 'remove_page',
  'add-note': 'add_note',
  'appendix': 'appendix',
  'add-appendix': 'add_appendix',
  'remove-appendix': 'remove_appendix',
  'preview': 'preview',
  'save': 'exit',
  'share': 'share',
  'duplicate': 'duplicate',
  'delete': 'delete',
  'read-note': 'note',
  'page-prev': 'previous_page',
  'page-next': 'next_page',
  'view-intro': 'intro',
  'page-list': 'pages',
  'contact': 'contact',
  'exit': 'exit',
  'return': 'return',
};

export default MenuMapping;
