import React, { useRef, useState, useEffect } from 'react';

import useAppStateStore from '../../../../../store/AppState.store';
import useWalkthroughStore from '../../../../../store/Walkthrough.store';
import useShareStore from '../../../../../store/Share.store';

import Button from '../../../atoms/Button/Button';
import Icon from '../../../atoms/Icon/Icon';

import ContactList from './ContactList/ContactList';
import ContactDetail from './ContactDetail/ContactDetail';
import ContactClientManager from './ContactClientManager/ContactClientManager';

import './Share.scss';

const Share = () => {
  const {
    data: { currentUserID, copy },
    actions: { handleSharingWalkthrough },
  } = useAppStateStore();
  const {
    data: { title, poc_group },
  } = useWalkthroughStore();
  const {
    data: { poc, activePOC, isAddingClient },
    actions: { handleSetData, handleSetActivePOC, handleSavePOC },
  } = useShareStore();
  const shareLabel = copy.screens.share;

  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [isCopyingLink, setIsCopyingLink] = useState(false);
  const copyBtnRef = useRef(null);

  const handleCopyLink = e => {
    const { target } = e;
    const value = `${window.location.origin}${window.location.pathname}?poc=${activePOC.point_of_contact.id}`;

    navigator.clipboard.writeText(value)
      .then(() => setIsCopyingLink(true))
      .catch(error => console.log(error));

    window.dataLayer.push({
      'event': 'walkthrough',
      'eventType': 'copy link click',
      'walkthroughTitle': title,
      'selectedUserId': currentUserID,
      'link': value,
    });

    if (target) {
      setTimeout(() => {
        setIsCopyingLink(false);
      }, 3000);
    }
  };

  const handleSaveShare = async e => {
    setIsSaving(true);
    const res = await handleSavePOC();

    if (res) {
      setIsSaving(false);
      handleSharingWalkthrough(e);
    }
  };

  useEffect(() => {
    handleSetData(poc_group);

    if (poc_group !== null) {
      handleSetActivePOC(poc_group[0]);
    }

    setIsLoading(false);
  }, [poc_group]);

  if (isLoading) {
    return (
      <div> Loading...</div>
    );
  }

  return (
    <div className="wt-share">
      <div className="wt-share__body">

        <div className="wt-share__main">
          <ContactList />
        </div>
        <div className="wt-share__panel">
          {(poc !== null && activePOC !== null) && (
            !isAddingClient ? <ContactDetail /> : <ContactClientManager />
          )}
        </div>

      </div>

      <div className="wt-share__footer">
        <div className="wt-share__copy">
          <p>{shareLabel.copy_link_description}</p>
          <Button
            classes="wt-share-copy-link"
            theme="on-white"
            onClick={handleCopyLink}
            ref={copyBtnRef}
          >
            {!isCopyingLink
              ? shareLabel.copy_link_submit_label
              : (
                <>
                  <Icon type="check" />
                  <span>Copied to clipboard</span>
                </>
              )}
          </Button>
        </div>
        <div className="wt-share__save">
          <Button
            theme="on-white"
            onClick={handleSaveShare}
            label={!isSaving ? 'Save & Exit' : 'Saving'}
          />
        </div>
      </div>

    </div>
  );
};

export default Share;
