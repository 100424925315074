import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import ContactAddPOC from '../ContactAddPOC/ContactAddPOC';

import useShareStore from '../../../../../../store/Share.store';

import './ContactList.scss';

const ContactCard = ({ poc, isActive, onSelectPOC, ...rest }) => {
  const { id, firstname, lastname, title, image } = poc;

  return (
    <button
      className={`wt-poc__card ${isActive ? 'is-active' : ''}`}
      onClick={() => onSelectPOC(id)}
      {...rest}
    >
      <div className="wt-poc__card-img">
        {image && <img src={image.src} alt={image.alt} />}
      </div>
      <div className="wt-poc__card-info">
        <div className="wt-poc__card-name">{firstname} {lastname}</div>
        <div className="wt-poc__card-title">{title}</div>
      </div>
    </button>
  );
};

const ContactList = () => {
  const {
    data: { poc, activePOC, isAddingPOC },
    actions: { handleSetActivePOC, handleIsAddingPOC },
  } = useShareStore();
  const [list, setList] = useState(poc);
  const [currActivePOC, setCurrActivePOC] = useState(activePOC);
  const [hasScrolled, setHasScrolled] = useState(false);
  const scrollRef = useRef(null);

  const handleSelectPOC = id => {
    const selectedPOC = list.find(poc => poc.point_of_contact.id === id);
    handleSetActivePOC(selectedPOC);
  };

  const handleAddPOC = () => {
    handleIsAddingPOC(!isAddingPOC);
  };

  const checkScroll = e => {
    const { target } = e;
    setHasScrolled(target.scrollTop === (target.scrollHeight - target.offsetHeight));
  };

  useEffect(() => {
    setList(poc);
    setCurrActivePOC(activePOC);

    if (scrollRef) {
      scrollRef.current.addEventListener('scroll', checkScroll);
    }
  }, [poc, activePOC]);

  return (
    <>
      <div className={`wt-poc__list ${isAddingPOC ? 'is-active' : ''}`}>
        <div className="wt-share__header">
          <h3>Points of Contact</h3>
          {(list === null || list.length < 5) && (
            <button className="wt-cta-button" onClick={handleAddPOC}>
              {!isAddingPOC ? 'Add POC' : 'Cancel Add'}
            </button>
          )}
        </div>
        <div className={`wt-poc__body ${hasScrolled ? 'has-scrolled' : ''}`} ref={scrollRef}>
          {(list && list !== null) ? (
            <ul>
              {list.map(p => {
                return (
                  <li>
                    <ContactCard
                      poc={p.point_of_contact}
                      onSelectPOC={handleSelectPOC}
                      isActive={currActivePOC.point_of_contact.id === p.point_of_contact.id}
                      tabIndex={isAddingPOC ? '-1' : null}
                    />
                  </li>
                );
              })}
            </ul>
          ) : (
            'No POC added yet.'
          )}
        </div>
      </div>

      {isAddingPOC && <ContactAddPOC />}
    </>
  );
};

ContactList.propTypes = {

};

export default ContactList;
