import React from 'react';
import PropTypes from 'prop-types';

import './Title.scss';
import '../FormInput.scss';

import Label from '../../Controls/Label/Label';
import TextInput from '../../Controls/TextInput/TextInput';
import Icon from '../../../Icon/Icon';

const TitleField = ({
  field,
  label,
  value,
  placeholder = null,
  required,
  classes,
  onChange,
  hasError,
}) => {
  const classNames = ['wt-form-input', hasError ? 'has-error has-value' : 'has-value', classes].join(' ');

  const handleChange = val => {
    onChange(field, val);
  };

  return (
    <div className={classNames}>
      <Label field={field} text={label} />
      <TextInput
        name={field}
        value={value}
        type="text"
        placeholder={placeholder}
        required={required}
        onChange={handleChange}
        hasError
      />
      {hasError ? (
        <div className="wt-info__title-error">
          <Icon type="exclamation" theme="light" />
          <p>can not save with the same name</p>
        </div>
      ) : ('')}
    </div>
  );
};

TitleField.propTypes = {
  required: PropTypes.bool,
  field: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  classes: PropTypes.string,
  hasError: PropTypes.bool,
};

TitleField.defaultProps = {
  required: false,
  field: 'title',
  label: '',
  value: null,
  classes: '',
  hasError: false,
};

export default TitleField;
