// Import Swiper React components
import React, { useState, useRef } from 'react';
import SwiperCore, { Pagination, Navigation, A11y, Controller } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
import './Slides.scss';

import Button from '../../atoms/Button/Button';
import LinkCTA from '../../atoms/Link/Link';

// install Swiper modules
SwiperCore.use([Navigation, Pagination, A11y, Controller]);

const Slider = ({ copy, slideData, onStart }) => {
  const [lastSlide, setLastSlide] = useState(false);

  // store swiper instances
  const [mainSlider, setMainSlider] = useState(null);
  const [imageSlider, setImageSlider] = useState(null);

  const handleSlideChange = e => {
    const nextBtn = document.querySelector('.slider-next-btn');
    const isLast = e.activeIndex === slideData.length - 1;

    setLastSlide(isLast);

    if (isLast) {
      nextBtn.addEventListener('click', onStart);
    } else {
      nextBtn.removeEventListener('click', onStart);
    }
  };

  const handleSlideAriaHidden = slides => {
    if (slides.length !== 0) {
      slides.forEach(item => {
        const slideHeading = item.querySelector('h2');
        if (item.classList.contains('swiper-slide-active')) {
          item.removeAttribute('aria-hidden');

          if (slideHeading) {
            slideHeading.setAttribute('tabindex', '0');
            slideHeading.focus();
          }
        } else {
          item.setAttribute('aria-hidden', 'true');
          if (slideHeading) {
            slideHeading.removeAttribute('tabindex');
          }
        }
      });
    }
  };

  return (
    <div className="slides">
      <div className="slides__main">
        <Swiper
          spaceBetween={30}
          slidesPerView={1}
          navigation={{
            nextEl: '.slider-next-btn',
            prevEl: '.slider-prev-btn',
          }}
          pagination={{
            el: '.swiper-pagination',
            clickable: true,
          }}
          onSlideChangeTransitionEnd={swiper => {
            const { slides } = swiper;
            handleSlideAriaHidden(slides);
          }}
          onAfterInit={swiper => {
            const { slides } = swiper;
            handleSlideAriaHidden(slides);
          }}
          onSlideChange={handleSlideChange}
          onSwiper={setMainSlider}
          controller={{ control: imageSlider }}
          allowTouchMove={false}
        >
          {slideData.map((slideItem, index) => {
            return (
              <SwiperSlide slide-id={slideItem.id} key={index}>
                <h2>{`${index + 1}. ${slideItem.title}`}</h2>
                <div className="slides__content" dangerouslySetInnerHTML={{ __html: slideItem.description }}></div>
                <div className="slides__img"><img src={slideItem.image} alt={slideItem.alt || slideItem.title} /></div>
              </SwiperSlide>
            );
          })}
          <div className="slider-navigation" slot="container-end">
            <Button
              theme="light"
              classes="slider-next-btn"
              label={lastSlide ? 'Get Started' : copy.next_cta_label}
            />
            <LinkCTA
              cta
              theme="light"
              classes="slider-prev-btn"
              label={copy.back_cta_label}
            />
          </div>
          <div className="swiper-pagination" slot="container-end"></div>
        </Swiper>
      </div>
      <div className="slides__image">
        <Swiper
          slidesPerView={1}
          spaceBetween={20}
          className="slides__main-image"
          onSlideChangeTransitionEnd={swiper => {
            const { slides } = swiper;
            handleSlideAriaHidden(slides);
          }}
          onAfterInit={swiper => {
            const { slides } = swiper;
            handleSlideAriaHidden(slides);
          }}
          onSwiper={setImageSlider}
          onSlideChange={handleSlideChange}
          controller={{ control: mainSlider }}
          allowTouchMove={false}
        >
          {slideData.map((slideItem, index) => {
            return (
              <SwiperSlide slide-image-id={slideItem.id} key={index}>
                <img src={slideItem.image} alt={slideItem.alt || slideItem.title} />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
};

export default Slider;
