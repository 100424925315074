import React from 'react';
import PropTypes from 'prop-types';

import './Label.scss';

const Label = ({
  text,
  field,
  hidden,
  inline,
  classes,
  ...props
}) => {
  const classNames = [
    'wt-form-label',
    hidden === 'true' ? 'wt-form-label-is-hidden' : '',
    classes,
  ].join(' ');

  return (
    <label className={classNames} htmlFor={field} {...props}>
      {text}
    </label>
  );
};

Label.propTypes = {
  text: PropTypes.string.isRequired,
  field: PropTypes.string.isRequired,
  hidden: PropTypes.string,
  inline: PropTypes.string,
  classes: PropTypes.string,
};

Label.defaultProps = {
  classes: '',
  hidden: 'false',
  inline: 'false',
};

export default Label;
