import React from 'react';
import useAppStateStore from '../../../../store/AppState.store';
import useWalkthroughStore from '../../../../store/Walkthrough.store';
import * as Helper from '../../../../utils/Helper';

import './CardContact.scss';

import Button from '../Button/Button';

const CardContact = ({ label = 'Email', contact }) => {
  const { firstname, lastname, email, phone, image, title } = contact || {};

  const onPing = e => {
    const { userType } = useAppStateStore.getState().data;
    const { title } = useWalkthroughStore.getState().data;
    const mail = document.createElement('a');
    mail.href = `mailto:${email}`;
    Helper.addDataLayer(userType, title, e.currentTarget.textContent);
    mail.click();
  };

  return (
    <div className="card card--contact">
      <div className="contact">
        <div className="contact-image">
          {image && <img src={image.src} alt={image.alt} />}
        </div>
        <div className="contact-info">
          <div className="contact-info__name">{firstname} {lastname}</div>
          <div className="contact-info__title">{title}</div>
          <div className="contact-info__phone"><a href={`tel:${phone}`}>{phone}</a></div>
        </div>
      </div>

      <Button classes="cta-btn" label={`${label} ${firstname} ${lastname && lastname.charAt(0)}.`} onClick={onPing} />
    </div>
  );
};

export default CardContact;
