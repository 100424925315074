import React from 'react';
import PropTypes from 'prop-types';

import './Text.scss';

const tags = {
  div: 'div',
  p: 'p',
  span: 'span',
};

const Text = ({ tag, label, children, classes, ...props }) => {
  const Tag = tags[tag] || 'p';

  return (
    <Tag
      className={`body-text ${classes}`}
      {...props}
      dangerouslySetInnerHTML={{ __html: label || children }}
    />
  );
};

Text.propTypes = {
  tag: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  classes: PropTypes.string,
};

Text.defaultProps = {
  classes: '',
};

export default Text;
