/* eslint-disable max-len */
import create from 'zustand';

const useStore = create(set => ({
  data: {
    isModalOpen: false,
  },
  actions: {
    handleModalToggle: () => (
      set(s => ({ data: { ...s.data, isModalOpen: !s.data.isModalOpen } }))
    ),
  },
}));

export default useStore;
