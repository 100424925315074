/* eslint-disable import/prefer-default-export */
/* eslint-disable no-restricted-globals */
/**
 * Clean iFrame Location URL
 *
 * @export
 * @param {obj} location iFrame Window.Location object
 * @return {string} Location.origin + pathname
 */
export function cleanIframeURL(location) {
  return `${location.origin}${location.pathname}`;
}

/**
 * Clean iFrame Location URL
 *
 * @export
 * @param {string} url Link
 * @return {string} Link
 */
export function cleanLocationURL(url) {
  return url.split('?')[0];
}

/**
 * Checks if current link is valid
 *
 * @export
 * @param {Object[]} urls List of pages + appendix
 * @return {boolean} True / False
 */
export function isValidURL(urls, link) {
  if (urls === null) {
    return false;
  }

  return urls.map(url => url.link).includes(link);
}

/**
 * Format Date
 *
 * @export
 * @param {string} url Date
 * @return {string} Date
 */
export function formatDate(date, format) {
  const months = {
    0: 'Jan',
    1: 'Feb',
    2: 'Mar',
    3: 'Apr',
    4: 'May',
    5: 'Jun',
    6: 'Jul',
    7: 'Aug',
    8: 'Sept',
    9: 'Oct',
    10: 'Nov',
    11: 'Dec',
  };
  const d = new Date(date);

  if (format === 'M/D') {
    return `${d.getMonth()}/${d.getDate()}`;
  }

  return `${months[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()}`;
}

/**
 * Get Url Parameter Values
 *
 * @export
 * @param {string} paramater Name
 * @return {string} Parameter Value
 */
export function getUrlParam(key) {
  const { search } = window.location;
  const urlParams = new URLSearchParams(search);

  if (hasUrlParam(urlParams, key)) {
    return urlParams.get(key);
  }

  return null;
}

export function hasUrlParam(params, key) {
  return params.has(key);
}

/**
 * Add datalayer for GTM
 *
 * @export
 * @param {int} paramater userType 1 for creator 2 for user (view only)
 * @param {sting} paramater Walkthrough title
 * @param {string} paramater Label or text of the button that is clicked
 */
export function addDataLayer(userType, title, menuItem) {
  window.dataLayer.push({
    'event': 'walkthrough',
    'eventType': userType === 1 ? 'creator menu click' : 'view only menu click',
    'walkthroughTitle': title,
    'menuItem': menuItem,
  });
}

/**
 * Get Timestamp (YMD_HMS)
 *
 * @export
 * @return
 */
export function getTimestamp() {
  const today = new Date();
  const date = `${today.getFullYear()}${today.getMonth() + 1}${today.getDate()}`;
  const time = `${today.getHours()}${today.getMinutes()}${today.getSeconds()}`;
  const dateTime = `${date}_${time}`;

  return dateTime;
}
