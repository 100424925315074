import React from 'react';
import PropTypes from 'prop-types';

import './Introduction.scss';
import '../FormInput.scss';

import Label from '../../Controls/Label/Label';
import RTE from '../../Controls/RTE/RTE';

const IntroductionField = ({
  field,
  value,
  placeholder,
  maxChar,
  required,
  classes,
  onChange,
}) => {
  const classNames = ['wt-form-input', 'wt-form-input--textarea', classes].join(' ');

  const handleChange = val => {
    onChange(field, val);
  };

  return (
    <div className={classNames}>
      <Label field={field} text="Introduction" hidden="true" />
      <RTE
        name={field}
        value={value}
        placeholder={placeholder}
        maxChar={maxChar}
        required={required}
        onChange={handleChange}
      />
    </div>
  );
};

IntroductionField.propTypes = {
  required: PropTypes.bool,
  field: PropTypes.string,
  classes: PropTypes.string,
  placeholder: PropTypes.string,
  maxChar: PropTypes.string,
};

IntroductionField.defaultProps = {
  required: false,
  field: 'description',
  classes: '',
  placeholder: 'Write FOX Selects Introduction..',
  maxChar: 240,
};

export default IntroductionField;
