import React, { useState } from 'react';
import ReactDragListView from 'react-drag-listview';

import useAppStateStore from '../../../../../store/AppState.store';
import useWalkthroughStore from '../../../../../store/Walkthrough.store';

import './PageList.scss';

import Screen, { ScreenBody, ScreenFooter } from '../../../atoms/Screen/Screen';
import Button from '../../../atoms/Button/Button';
import List from '../../../atoms/List/List';

const PageList = () => {
  const { userType, copy } = useAppStateStore().data;
  const {
    data: { items },
    actions: { handleSave },
  } = useWalkthroughStore();
  const pagesLabel = copy.screens.other;
  const [list, setList] = useState(items);
  const [isSaving, setIsSaving] = useState(false);

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      const data = [...list];
      const item = data.splice(fromIndex, 1)[0];
      data.splice(toIndex, 0, item);
      setList(data);
    },
    nodeSelector: 'li',
    handleSelector: 'button',
    lineClassName: 'wt-list__line',
  };

  const handleSubmit = async () => {
    // prepare / validate data
    // call state action
    setIsSaving(true);

    const res = await handleSave({ items: list });

    // If successful
    if (res) {
      setIsSaving(false);
    }
  };

  return (
    <Screen title={pagesLabel.page_title} classes="wt-screen--pages">
      <ScreenBody>
        <div className="wt-pagelist">
          {userType === 1 && (
            <ReactDragListView {...dragProps}>
              <List list={list} type="default" />
            </ReactDragListView>
          )}

          {userType === 2 && <List list={items} />}
        </div>
      </ScreenBody>
      {userType === 1 && (
        <ScreenFooter>
          <div className="wt-form-input">
            <Button
              label={!isSaving ? pagesLabel.page_submit_cta_label : 'Saving'}
              classes="cta-btn"
              onClick={handleSubmit}
              disabled={list === null || list.length <= 1}
            />
          </div>
        </ScreenFooter>
      )}
    </Screen>
  );
};

export default PageList;
