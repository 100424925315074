import React from 'react';
import PropTypes from 'prop-types';

import './Tooltip.scss';

const Tooltip = ({ text, classes = null, position = null, children, ...props }) => {
  const placement = position ? `wt-tooltip--${position}` : '';
  return (
    <div className={`wt-tooltip ${placement} ${classes}`} data-text={text} {...props}>
      {children}
    </div>
  );
};

Tooltip.propTypes = {
  text: PropTypes.string.isRequired,
  classes: PropTypes.string,
  position: PropTypes.string,
};

Tooltip.defaultProps = {
  classes: '',
  position: 'left',
};

export default Tooltip;
