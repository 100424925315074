import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import './TextInput.scss';

const TextInput = ({
  name = '',
  type = 'text',
  value,
  required,
  classes,
  onChange,
  placeholder,
  readonly,
  hasError,
}) => {
  const classNames = [
    'wt-form-input__control',
    'wt-form-input__control-text',
    classes,
  ].join(' ');
  const inputRef = useRef(null);

  const handleOnChange = e => {
    if (e !== undefined) {
      const target = e.target || e;
      const { value, parentNode } = target;
      parentNode.classList.remove('has-error');

      onChange(value !== '' ? value : undefined);
    }
  };

  const handleFocus = e => {
    e.target.parentNode.classList.add('has-focus');
  };

  const handleBlur = e => {
    e.target.parentNode.classList.remove('has-focus');
    handleCheckValue(e, 'blur');
  };

  const handleCheckValue = (e, type) => {
    const target = e.target || e;
    const parent = target.parentNode;
    const { value } = target;
    const eType = type || 'default';

    if (value !== '') {
      parent.classList.add('has-value');
      parent.classList.remove('has-error');
    } else {
      parent.classList.remove('has-value');
    }

    if (required && value === '') {
      parent.classList.add('has-error');
    } else {
      parent.classList.remove('has-error');
    }

    if (onChange) {
      handleOnChange(e);
      if (eType === 'blur') {
        parent.classList.remove('has-error');
      }
    }
  };

  useEffect(() => {
    if (value !== null && inputRef.current) {
      inputRef.current.parentNode.classList.add('has-value');
    }
  }, [value]);

  return (
    <input
      id={name}
      name={name}
      type={type}
      defaultValue={value}
      autoComplete="off"
      className={classNames}
      onFocus={handleFocus}
      onChange={handleOnChange}
      onBlur={handleBlur}
      placeholder={placeholder}
      readOnly={readonly}
      ref={inputRef}
    />
  );
};

TextInput.propTypes = {
  /* Built-in properties */
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf([
    'text',
    'number',
    'password',
    'date',
    'email',
    'tel',
    'url',
    'search',
  ]).isRequired,
  placeholder: PropTypes.string,

  /* Custom Classes */
  classes: PropTypes.string,

  required: PropTypes.bool,
  readonly: PropTypes.bool,
  onChange: PropTypes.func,
  hasError: PropTypes.bool,
};

TextInput.defaultProps = {
  classes: '',
  required: false,
  readonly: false,
  placeholder: null,
  onChange: null,
  hasError: false,
};

export default TextInput;
