import React from 'react';
// import PropTypes from 'prop-types';

import useAppStateStore from '../../../../store/AppState.store';
import useWalkthroughStore from '../../../../store/Walkthrough.store';

import Modal from '../../atoms/Modal/ConfirmModal';
import Button from '../../atoms/Button/Button';
import Heading from '../../atoms/Heading/Heading';
import Text from '../../atoms/Text/Text';

const DuplicateModal = ({ isOpen }) => {
  const {
    data: { copy, isViewingTemplate },
    actions: { handleDuplicatingWalkthrough },
  } = useAppStateStore();
  const {
    actions: { handleDuplicateWalkthrough, handleDuplicateTemplate },
  } = useWalkthroughStore();
  const confirmLabel = copy.confirmation.duplicate;

  return (
    <div className="modal-confirm modal-confirm--exit">
      <Modal isModalOpen={isOpen}>
        <Heading level="2" classes="wt-title-text--3 wt-confirm-modal__confirm-heading">
          {confirmLabel.title}
        </Heading>
        <Text tag="p" classes="wt-confirm-modal__description">
          {!isViewingTemplate
            ? confirmLabel.description
            : 'This will create a copy of this template.'}
        </Text>
        <div className="wt-confirm-modal__controls">
          <Button
            theme="light"
            classes="wt-confirm-modal__cta-1"
            onClick={!isViewingTemplate ? handleDuplicateWalkthrough : handleDuplicateTemplate}
            label={confirmLabel.cta_confirm}
          />
          <Button
            theme="light"
            classes="wt-confirm-modal__cta-2"
            onClick={handleDuplicatingWalkthrough}
            label={confirmLabel.cta_deny}
          />
        </div>
      </Modal>
    </div>
  );
};

// DuplicateModal.propTypes = {};

export default DuplicateModal;
