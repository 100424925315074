import React, { useState } from 'react';
import Icon from '../../../atoms/Icon/Icon';

import './PanelToggle.scss';

const PanelToggle = ({ children, title }) => {
  const [isToggled, stIsToggled] = useState(false);
  const className = ['wt-panel__toggle', isToggled ? 'wt-panel__toggle--is-active' : ''].join(' ');

  const handleToggle = () => {
    stIsToggled(!isToggled);
  };

  return (
    <div className={className}>
      <button className="wt-panel__toggle-action" onClick={handleToggle} aria-label={title}>
        <Icon type="caret-up" />
      </button>
      {children}
    </div>
  );
};

export default PanelToggle;
