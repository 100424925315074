/* eslint-disable max-len */
import create from 'zustand';

import useWalkthroughStore from './Walkthrough.store';

const useStore = create((set, get) => ({
  data: {
    poc: null,
    activePOC: null,
    activePOCGroupIndex: null,
    hasEdited: false,
    isAddingPOC: false,
    isAddingClient: false,
  },
  actions: {
    handleGetGroupIndex: id => {
      const currentGroupIndex = get().data.poc.findIndex(p => p.point_of_contact.id === id);
      return currentGroupIndex;
    },
    handleSetData: data => {
      set(s => ({
        data: {
          ...s.data,
          poc: data,
        },
      }));
    },
    handleSetActivePOC: data => {
      if (data !== null && data.point_of_contact !== null) {
        const poc_id = data.point_of_contact.id;
        set(s => ({
          data: {
            ...s.data,
            activePOC: data,
            activePOCGroupIndex: data !== null ? get().actions.handleGetGroupIndex(poc_id) : null,
            isAddingClient: false,
          },
        }));
      }
    },
    handleSavePOC: () => {
      const newData = {
        poc_group: get().data.poc,
      };

      const res = useWalkthroughStore.getState().actions.handleSave(newData);

      return res;
    },
    handleAddNewPOC: val => {
      const currPOC = get().data.poc || [];
      const newPOC = {
        point_of_contact: val,
        conclusion: null,
        clients: null,
      };

      currPOC.push(newPOC);

      set(s => ({
        data: {
          ...s.data,
          poc: currPOC,
          hasEdited: true,
        },
      }));

      if (currPOC.length === 1) {
        set(s => ({
          data: {
            ...s.data,
            activePOC: currPOC[0],
            activePOCGroupIndex: 0,
          },
        }));
      } else {
        set(s => ({
          data: {
            ...s.data,
            activePOC: newPOC,
            activePOCGroupIndex: currPOC.length - 1,
          },
        }));
      }

      get().actions.handleIsAddingPOC();
    },
    handleRemovePOC: id => {
      const newPOCs = get().data.poc.filter(p => p.point_of_contact.id !== id);

      set(s => ({
        data: {
          ...s.data,
          poc: newPOCs.length > 0 ? newPOCs : null,
          activePOC: newPOCs.length > 0 ? newPOCs[0] : null,
          activePOCGroupIndex: newPOCs.length > 0 ? 0 : null,
          hasEdited: true,
        },
      }));
    },
    handleUpdateConclusion: val => {
      const currentPOC = {
        ...get().data.activePOC,
        conclusion: val,
      };
      const updatedPOCs = get().data.poc;
      updatedPOCs[get().data.activePOCGroupIndex] = currentPOC;

      set(s => ({
        data: {
          ...s.data,
          poc: updatedPOCs,
          activePOC: currentPOC,
          hasEdited: true,
        },
      }));
    },
    handleAddClient: val => {
      const currentPOC = {
        ...get().data.activePOC,
        clients: val,
      };
      const updatedPOCs = get().data.poc;
      updatedPOCs[get().data.activePOCGroupIndex] = currentPOC;

      set(s => ({
        data: {
          ...s.data,
          poc: updatedPOCs,
          activePOC: currentPOC,
          hasEdited: true,
        },
      }));
    },
    handleRemoveClient: val => {
      const currentPOC = {
        ...get().data.activePOC,
        clients: val,
      };
      const updatedPOCs = get().data.poc;
      updatedPOCs[get().data.activePOCGroupIndex] = currentPOC;

      set(s => ({
        data: {
          ...s.data,
          poc: updatedPOCs,
          activePOC: currentPOC,
          hasEdited: true,
        },
      }));
    },
    handleIsAddingPOC: () => {
      set(s => ({
        data: {
          ...s.data,
          isAddingPOC: !get().data.isAddingPOC,
        },
      }));
    },
    handleIsAddingClient: () => {
      set(s => ({
        data: {
          ...s.data,
          isAddingClient: !get().data.isAddingClient,
        },
      }));
    },
  },
}));

export default useStore;
