import React, { useState } from 'react';
// import PropTypes from 'prop-types';

import useAppStateStore from '../../../../store/AppState.store';
import useWalkthroughStore from '../../../../store/Walkthrough.store';

import Modal from '../../atoms/Modal/ConfirmModal';
import Button from '../../atoms/Button/Button';
import Heading from '../../atoms/Heading/Heading';
import Text from '../../atoms/Text/Text';

const DeleteModal = ({ isOpen }) => {
  const {
    data: { copy },
    actions: { handleDeletingWalkthrough },
  } = useAppStateStore();
  const { handleDeleteWalkthrough } = useWalkthroughStore().actions;
  const confirmLabel = copy.confirmation.delete;
  const [isDeleting, setIsDeleting] = useState(false);

  const handleOnDelete = async () => {
    const res = await handleDeleteWalkthrough();
    setIsDeleting(!isDeleting);

    if (res) {
      setIsDeleting(!isDeleting);
      window.location = `${window.location.origin}/walkthroughs`;
    }
  };

  return (
    <div className="modal-confirm modal-confirm--exit">
      <Modal isModalOpen={isOpen} onClose={handleDeletingWalkthrough}>
        <Heading level="2" classes="wt-title-text--3 wt-confirm-modal__confirm-heading">
          {confirmLabel.title}
        </Heading>
        {confirmLabel.description && (
          <Text tag="p" classes="wt-confirm-modal__description">
            {confirmLabel.description}
          </Text>
        )}
        <div className="wt-confirm-modal__controls">
          <Button
            theme="light"
            classes="wt-confirm-modal__cta-1"
            onClick={handleOnDelete}
            label={!isDeleting ? confirmLabel.cta_confirm : 'Deleting'}
          />
          <Button
            theme="light"
            classes="wt-confirm-modal__cta-2"
            onClick={handleDeletingWalkthrough}
            label={confirmLabel.cta_deny}
          />
        </div>
      </Modal>
    </div>
  );
};

// DeleteModal.propTypes = {};

export default DeleteModal;
