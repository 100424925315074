import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import './TextBox.scss';

const TextBox = ({
  name,
  value,
  placeholder,
  required,
  maxChar,
  classes,
  onChange,
}) => {
  const [count, setCount] = useState(0);
  const [hasMax, setHasMax] = useState(false);
  const inputRef = useRef(null);

  const classNames = [
    'wt-form-input__control',
    'wt-form-input__control-textarea',
    classes,
  ].join(' ');

  const handleOnChange = e => {
    const target = e.target || e;
    const { value } = target;

    if (count >= maxChar) {
      e.preventDefault();
    }

    if (count > maxChar) {
      setHasMax(true);
      onChange(undefined);
    } else {
      setHasMax(false);
      onChange(value);
    }

    setCount(value.length);
  };

  const handleBlur = () => {
    handleCheckValue(inputRef.current);
  };

  const handleCheckValue = e => {
    const target = e.target || e;
    const parent = target.parentNode;
    const { value } = target;

    if (value !== '') {
      parent.classList.add('has-value');
      parent.classList.remove('has-error');
    } else {
      parent.classList.remove('has-value');
    }

    if (required && value === '') {
      parent.classList.add('has-error');
    } else {
      parent.classList.remove('has-error');
    }

    if (onChange) {
      onChange(value);
    }
  };

  useEffect(() => {
    if (value !== null && value !== undefined && inputRef.current) {
      inputRef.current.parentNode.classList.add('has-value');
      setCount(value.length);
    }
  }, []);

  return (
    <>
      <textarea
        id={name}
        name={name}
        className={classNames}
        placeholder={placeholder}
        onBlur={handleBlur}
        onKeyUp={handleOnChange}
        ref={inputRef}
      >
        {value}
      </textarea>
      <div className="wt-text-counter">
        <span style={hasMax ? { color: 'red' } : null}>{count}</span>/{maxChar}
      </div>
    </>
  );
};

TextBox.propTypes = {
  /* Built-in properties */
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,

  /* Custom Classes */
  classes: PropTypes.string,

  required: PropTypes.bool,
};

TextBox.defaultProps = {
  classes: '',
  required: false,
};

export default TextBox;
