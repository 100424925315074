import React, { useState } from 'react';
import PropTypes from 'prop-types';

import useAppStateStore from '../../../../../../store/AppState.store';
import useShareStore from '../../../../../../store/Share.store';
import useWalkthroughStore from '../../../../../../store/Walkthrough.store';

import Autocomplete from '../../../../atoms/Form/Controls/Autocomplete/Autocomplete';
import Label from '../../../../atoms/Form/Controls/Label/Label';

// TODO:
// Fix Copy
// Filter Added POC option

const ContactAddPOC = () => {
  const {
    data: { copy, users },
  } = useAppStateStore();
  const {
    data: { title },
  } = useWalkthroughStore();
  const {
    data: { poc },
    actions: { handleAddNewPOC },
  } = useShareStore();

  const [input, setInput] = useState(null);

  const handleChange = val => {
    let newValue;

    if (val !== undefined) {
      newValue = {
        id: val.ID,
        email: val.email,
        firstname: val.first_name,
        lastname: val.last_name,
        name: val.name,
        title: val.title,
        image: val.image,
      };
    }

    setInput(newValue);
  };

  const handleAdd = () => {
    if (input !== undefined && input !== null) {
      handleAddNewPOC(input);

      window.dataLayer.push({
        'event': 'walkthrough',
        'eventType': 'add point of contact click',
        'walkthroughTitle': title,
        'selectedUserId': input.id,
      });
    }

    setInput(null);
  };

  return (
    <div className="wt-poc__add-new">
      <div className="wt-share__header">
        <h3>Add Points of Contact</h3>
      </div>

      <div className="wt-poc__add-new-body">
        <div className="wt-form-input wt-form-input-autocomplete">
          <Label field="poc-ad" text="Add Points of Contact" hidden />
          <Autocomplete
            name="poc-add"
            value={input}
            options={users.authors}
            onChange={handleChange}
            searchBy="name"
          />
        </div>
      </div>

      <button className="wt-poc__add-new-btn wt-cta-button" onClick={handleAdd}>Add</button>
    </div>
  );
};

ContactAddPOC.propTypes = {

};

export default ContactAddPOC;
