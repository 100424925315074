import create from 'zustand';

import * as API from '../utils/APIs';
import * as Helper from '../utils/Helper';
import useAppStateStore from './AppState.store';

import audio from '../assets/ping.wav';

const useStore = create((set, get) => ({
  data: {},
  actions: {
    // =======================
    // STATE ACTIONS SETTERS
    // =======================
    handleSetInitialData: data => {
      set(s => ({
        data: {
          ...s.data,
          ...data,
        },
      }));
    },
    handleResetInitialData: res => {
      if (res) {
        get().actions.handleSetInitialData(res);
      }
      return res;
    },

    // =======================
    // API ACTION CALLS
    // =======================
    handleSave: async data => {
      const { userType, currentScreenLabel } = useAppStateStore.getState().data;
      const { title } = get().data;
      const { id } = get().data;
      let res = null;

      if (id) {
        res = await API.updateWalkthrough(JSON.stringify(data), id);
        if (res) {
          const dataRes = Object.keys(res);
          if (dataRes.indexOf('data') === -1) {
            get().actions.handleResetInitialData(res);
          }

          // Check if the data comes from the Share/Conclusion Modal
          // CHeck if 'clients' or 'conclusion' exist in data and it's only one
          // const keys = Object.keys(data);
          // const key = keys[0];
          // if (key !== 'clients' && key !== 'conclusion') {
          //   if (dataRes.indexOf('data') === -1) {
          //     useAppStateStore.getState().actions.handlePanelCollapse();
          //   }
          // }
        }

        Helper.addDataLayer(userType, title, currentScreenLabel);

        return res;
      }

      res = await API.addWalkthrough(JSON.stringify(data));
      if (res) {
        const dataRes = Object.keys(res);
        if (dataRes.indexOf('data') === -1) {
          get().actions.handleResetInitialData(res);
          useAppStateStore.getState().actions.handleContinueCreateWalkthrough(res.link);
        }
      }

      Helper.addDataLayer(userType, title, currentScreenLabel);

      return res;
    },
    handleAddPage: async e => {
      const { id, items, title } = get().data;
      const { currentTarget } = e;
      const ariaLabel = currentTarget.getAttribute('aria-label');
      const { currentPageViewingID, userType } = useAppStateStore.getState().data;
      const newItems = items || [];
      newItems.push({ id: currentPageViewingID });
      const res = await API.updateWalkthrough(JSON.stringify({ items: newItems }), id);
      get().actions.handleResetInitialData(res);

      useAppStateStore.setState({
        data: {
          ...useAppStateStore.getState().data,
          currentWalkthroughItemIndex: items.length - 1,
          currentScreenViewing: 'Note',
          isPanelCollapsed: false,
        },
      });

      Helper.addDataLayer(userType, title, ariaLabel);

      const audioToPlay = new Audio(audio);
      audioToPlay.play();
    },
    handleRemovePage: async e => {
      const { id, items, title } = get().data;
      const { currentTarget } = e;
      const ariaLabel = currentTarget.getAttribute('aria-label');
      const { currentWalkthroughItemIndex, userType } = useAppStateStore.getState().data;
      const newItems = items.filter(item => item.id !== items[currentWalkthroughItemIndex].id);
      const res = await API.updateWalkthrough(JSON.stringify({ items: newItems }), id);
      get().actions.handleResetInitialData(res);

      useAppStateStore.setState({
        data: {
          ...useAppStateStore.getState().data,
          currentWalkthroughItemIndex: 0,
        },
      });

      Helper.addDataLayer(userType, title, ariaLabel);
    },
    handleAddAppendix: async () => {
      const { id, appendix } = get().data;
      const { currentPageViewingID } = useAppStateStore.getState().data;
      const newItems = appendix || [];
      newItems.push({ id: currentPageViewingID });
      const res = await API.updateWalkthrough(JSON.stringify({ appendix: newItems }), id);
      get().actions.handleResetInitialData(res);
    },
    handleRemoveAppendix: async () => {
      const { id, appendix } = get().data;
      const { currentPageViewingID } = useAppStateStore.getState().data;
      const newItems = appendix.filter(app => app.id !== parseInt(currentPageViewingID));
      const res = await API.updateWalkthrough(JSON.stringify(
        { appendix: newItems.length === 0 ? '' : newItems },
      ), id);
      get().actions.handleResetInitialData(res);
    },
    handleDeleteWalkthrough: async () => {
      const { id } = get().data;
      const res = await API.deleteWalkthrough(id);
      return res;
    },
    handleDuplicateWalkthrough: async () => {
      const dataToCopy = get().data;
      delete dataToCopy.id;
      delete dataToCopy.date;

      const formData = {
        ...dataToCopy,
        title: `Copy of ${get().data.title} (${Helper.getTimestamp()})`,
      };

      const res = await API.addWalkthrough(JSON.stringify(formData));

      if (res) {
        get().actions.handleResetInitialData(res);

        useAppStateStore.getState().actions.handleContinueDuplicateWalkthrough(res.link);
      }
    },
    handleDuplicateTemplate: async () => {
      const dataToCopy = get().data;
      delete dataToCopy.id;
      delete dataToCopy.date;
      delete dataToCopy.editor;
      delete dataToCopy.point_of_contact;

      const formData = {
        ...dataToCopy,
        title: `Copy of ${get().data.title} (${Helper.getTimestamp()})`,
      };

      const res = await API.addWalkthrough(JSON.stringify(formData));

      if (res) {
        get().actions.handleResetInitialData(res);

        useAppStateStore.getState().actions.handleContinueDuplicateWalkthrough(res.link);
      }
    },
    handleExistingWalkthroughList: list => {
      set(s => ({ data: { ...s.data, existingList: list } }));
    },
  },
}));

export default useStore;
