import React from 'react';

import useAppStateStore from '../../../../../store/AppState.store';
import useWalkthroughStore from '../../../../../store/Walkthrough.store';

import List from '../../../atoms/List/List';
import Screen, { ScreenBody } from '../../../atoms/Screen/Screen';

import './Existing.scss';

const Existing = () => {
  const { copy } = useAppStateStore().data;
  const existingLabel = copy.screens.other;
  const { existingList } = useWalkthroughStore().data;

  return (
    <Screen title={existingLabel.existing_title} classes="wt-screen--existing">
      <ScreenBody>
        <div className="wt-existing">
          <List list={existingList} type="existing" limit="5" />
        </div>
      </ScreenBody>
    </Screen>
  );
};

export default Existing;
