import React from 'react';
import ReactDOM from 'react-dom';

import App from './ui/components/walkthrough/App/App';

// import reportWebVitals from './reportWebVitals';

const Start = () => <App />;

const targets = document.querySelectorAll('.wtr-root');
targets.forEach(target => {
  ReactDOM.render(<Start />, target);
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
