export const creator_tasks_1 = [
  {
    id: 'edit-info',
    name: 'Edit Information',
    icon: 'edit',
    showHoverLabel: true,
    eventHandler: 'handleShowScreen',
    screenToShow: 'InfoScreen',
  },
  {
    id: 'edit-order',
    name: 'Edit Page Order',
    icon: 'stack',
    showHoverLabel: true,
    eventHandler: 'handleShowScreen',
    screenToShow: 'PageList',
  },
  {
    id: 'add-page',
    name: 'Add This Page',
    icon: 'add',
    showHoverLabel: true,
    eventHandler: 'handleAddPage',
  },
  {
    id: 'remove-page',
    name: 'Remove This Page',
    icon: 'remove',
    showHoverLabel: true,
    eventHandler: 'handleRemovePage',
  },
  {
    id: 'add-note',
    name: 'Add Note',
    icon: 'note',
    showHoverLabel: true,
    eventHandler: 'handleShowScreen',
    screenToShow: 'Note',
  },
  {
    id: 'appendix',
    name: 'Appendix',
    icon: 'appendix',
    showHoverLabel: true,
    eventHandler: 'handleShowScreen',
    screenToShow: 'Appendix',
  },
  {
    id: 'add-appendix',
    name: 'Add This Page',
    icon: 'appendix-add',
    showHoverLabel: true,
    eventHandler: 'handleAddAppendix',
  },
  {
    id: 'remove-appendix',
    name: 'Remove This Page',
    icon: 'appendix-remove',
    showHoverLabel: true,
    eventHandler: 'handleRemoveAppendix',
  },
];

export const creator_tasks_2 = [
  {
    id: 'preview',
    name: 'Preview',
    icon: 'eye',
    showHoverLabel: true,
    eventHandler: 'handleStartPreview',
  },
  {
    id: 'save',
    name: 'Save & Exit',
    icon: 'exit',
    showHoverLabel: true,
    eventHandler: 'handleClosingWalkthrough',
  },
  {
    id: 'share',
    name: 'Share',
    icon: 'share',
    showHoverLabel: true,
    eventHandler: 'handleSharingWalkthrough',
  },
  {
    id: 'duplicate',
    name: 'Duplicate',
    icon: 'duplicate',
    showHoverLabel: true,
    eventHandler: 'handleDuplicatingWalkthrough',
  },
  {
    id: 'delete',
    name: 'Delete',
    icon: 'delete',
    showHoverLabel: true,
    eventHandler: 'handleDeletingWalkthrough',
  },
];

export const client_tasks_1 = [
  {
    id: 'read-note',
    name: 'Read Note',
    icon: 'note',
    showHoverLabel: true,
    eventHandler: 'handleShowScreen',
    screenToShow: 'Note',
  },
  {
    id: 'page-prev',
    name: 'Previous Page',
    icon: 'arrow-left',
    showHoverLabel: false,
    eventHandler: 'handlePrevPage',
  },
  {
    id: 'page-next',
    name: 'Next Page',
    icon: 'arrow-right',
    showHoverLabel: false,
    eventHandler: 'handleNextPage',
  },
];

export const client_tasks_2 = [
  {
    id: 'view-intro',
    name: 'View Intro',
    icon: 'info',
    showHoverLabel: true,
    eventHandler: 'handleShowScreen',
    screenToShow: 'IntroScreen',
  },
  {
    id: 'page-list',
    name: 'See Page List',
    icon: 'stack',
    showHoverLabel: true,
    eventHandler: 'handleShowScreen',
    screenToShow: 'PageList',
  },
  {
    id: 'contact',
    name: 'Contact AE',
    icon: 'mail',
    showHoverLabel: true,
    eventHandler: 'handleShowScreen',
    screenToShow: 'Contact',
  },
  {
    id: 'exit',
    name: 'Exit Walkthrough',
    icon: 'exit',
    showHoverLabel: true,
    eventHandler: 'handleExitingWalkthrough',
  },
];

export const exit_task = [
  {
    id: 'exit',
    name: 'Exit',
    icon: 'close',
    showHoverLabel: false,
    eventHandler: 'handleExitingWalkthrough',
  },
];

export const return_task = [
  {
    id: 'return',
    name: 'Return to Fox Selects',
    icon: 'return',
    showHoverLabel: true,
    eventHandler: 'handleReturnWalkthrough',
  },
];
