import React from 'react';
import PropTypes from 'prop-types';

import './AddNote.scss';
import '../FormInput.scss';

import Label from '../../Controls/Label/Label';
import TextBox from '../../Controls/TextBox/TextBox';

const NoteField = ({ field, value, placeholder, maxChar, required, classes, onChange }) => {
  const classNames = [
    'wt-form-input',
    'wt-form-input--textarea',
    classes,
  ].join(' ');

  const handleChange = val => {
    onChange(val);
  };

  return (
    <div className={classNames}>
      <Label field={field} text="Walkthrough Note" hidden="true" />
      <TextBox
        name={field}
        value={value}
        placeholder={placeholder}
        maxChar={maxChar}
        required={required}
        onChange={handleChange}
      />
    </div>
  );
};

NoteField.propTypes = {
  required: PropTypes.bool,
  classes: PropTypes.string,
  placeholder: PropTypes.string,
  maxChar: PropTypes.string,
};

NoteField.defaultProps = {
  required: false,
  classes: '',
  placeholder: 'Default message here to explore the page. Then AE can write in an additional message.',
  maxChar: '400',
};

export default NoteField;
