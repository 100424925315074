import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import useShareStore from '../../../../../../store/Share.store';

import SelectionList from '../../../../atoms/Form/Controls/SelectionList/SelectionList';

import './ContactClients.scss';

const ContactClients = ({ heading }) => {
  const {
    data: { activePOC, isAddingClient },
    actions: { handleRemoveClient, handleIsAddingClient },
  } = useShareStore();

  const [clients, setClients] = useState(activePOC.clients || []);

  const handleAddClients = () => {
    handleIsAddingClient();
  };

  const handleRemove = id => {
    const newClients = clients.filter(client => client.id !== id);
    handleRemoveClient(newClients);
    setClients(newClients);
  };

  useEffect(() => {
    setClients(activePOC.clients);
  }, [activePOC]);

  return (
    <div className="wt-clients">
      <div className="wt-share__header">
        <h3>{heading}</h3>

        {!isAddingClient && <button className="wt-cta-button" onClick={handleAddClients}>Add Clients</button>}
      </div>

      <SelectionList list={clients} emptyLabel="No clients added yet." onRemove={handleRemove} />

    </div>
  );
};

ContactClients.propTypes = {

};

export default ContactClients;
