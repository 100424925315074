import React, { useState, useEffect } from 'react';

import useAppStateStore from '../../../../../store/AppState.store';
import useWalkthroughStore from '../../../../../store/Walkthrough.store';

import './Contact.scss';

import * as Helper from '../../../../../utils/Helper';

import Screen, { ScreenBody } from '../../../atoms/Screen/Screen';
import CardContact from '../../../atoms/CardContact/CardContact';

const Contact = () => {
  const { poc_group } = useWalkthroughStore().data;
  const {
    data: { copy },
  } = useAppStateStore();
  const contactLabel = copy.screens.contact;
  const [activePOC, setActivePOC] = useState(null);

  useEffect(() => {
    let pocIndex;

    // Check for Active POC ID in URL params
    // Else get in window.dataLayer_content
    if (Helper.getUrlParam('poc')) {
      pocIndex = Helper.getUrlParam('poc');
    } else {
      pocIndex = window.dataLayer_content.activePOC;
    }

    // Check if Active POC ID is existing
    // Else, set first item in POC_group data array
    if (pocIndex) {
      const active = poc_group.find(poc => poc.point_of_contact.id === parseInt(pocIndex));

      if (active) {
        setActivePOC(active.point_of_contact);
      } else {
        setActivePOC(poc_group[0].point_of_contact);
      }
    }
  }, [poc_group]);

  return (
    <Screen title={contactLabel.title} classes="wt-screen--contact">
      <ScreenBody>
        <div className="wt-contact">
          <CardContact label={contactLabel.cta_label_prefix} contact={activePOC} />
        </div>
      </ScreenBody>
    </Screen>
  );
};

export default Contact;
