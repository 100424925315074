/* eslint-disable max-len */
import React from 'react';

import './FTUE_Landing.scss';

import Button from '../../../atoms/Button/Button';
import LinkCTA from '../../../atoms/Link/Link';
import Heading from '../../../atoms/Heading/Heading';
import Text from '../../../atoms/Text/Text';

const FTUE_Landing = ({ copy, onSkip, onContinue }) => {
  const handleShowSteps = () => {
    onContinue(true);
  };

  const handleSkipWalkthrough = () => {
    onSkip(true);
  };

  return (
    <div className="wt-ftue-landing">
      <div className="wt-ftue-landing__content">
        <Heading level="2" classes="wt-title-text--3 wt-ftue-landing__heading">
          {copy.title}
        </Heading>
        <Text tag="p" classes="wt-ftue-landing__description">
          {copy.description}
        </Text>
      </div>
      <div className="wt-ftue-landing__controls">
        <Button
          theme="light"
          classes="wt-ftue-landing__cta-1"
          onClick={handleShowSteps}
          label={copy.start_cta_label}
        />
        <LinkCTA
          cta
          theme="light"
          classes="wt-ftue-landing__cta-2"
          onClick={handleSkipWalkthrough}
          label={copy.skip_cta_label}
        />
      </div>
    </div>
  );
};

export default FTUE_Landing;
