import React from 'react';

import useAppStateStore from '../../../../store/AppState.store';
import useWalkthroughStore from '../../../../store/Walkthrough.store';

import Modal from '../../atoms/Modal/Modal';
import Share from '../Screens/Share/Share';

const ShareModal = ({ isOpen }) => {
  const { handleSharingWalkthrough } = useAppStateStore().actions;
  const { title } = useWalkthroughStore().data;

  return (
    <Modal
      isModalOpen={isOpen}
      onClose={handleSharingWalkthrough}
      classes="wt-modal--share"
      heading={`Share your ${title}`}
    >
      <Share />
    </Modal>
  );
};

export default ShareModal;
