import React from 'react';
import PropTypes from 'prop-types';

import useShareStore from '../../../../../../store/Share.store';

import ContactConclusion from '../ContatctConclusion/ContactConclusion';
import ContactClients from '../ContactClients/ContactClients';

const ContactDetail = () => {
  const {
    data: { activePOC },
    actions: { handleRemovePOC },
  } = useShareStore();

  const contact = activePOC.point_of_contact;

  const handleRemove = () => {
    handleRemovePOC(contact.id);
  };

  return (
    <>
      <div className="wt-poc-detail">
        <div className="wt-share__header">
          <h3>{contact.firstname} {contact.lastname}</h3>

          <button className="wt-cta-button" onClick={handleRemove}>Remove as POC</button>
        </div>

        <div className="wt-poc-conclusion">
          <ContactConclusion />
        </div>

        <div className="wt-poc-clients">
          <ContactClients heading="Access Granted" />
        </div>
      </div>
    </>
  );
};

ContactDetail.propTypes = {

};

export default ContactDetail;
