import React from 'react';

import './Logo.scss';

const Logo = ({ theme = 'dark', classes = '' }) => {
  const className = [
    'wt-logo',
    classes,
    theme !== 'dark' ? 'wt-logo--white' : '',
  ].join(' ');

  return <i className={className} />;
};

export default Logo;
