/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';

import useAppStateStore from '../../../../../store/AppState.store';
import useWalkthroughStore from '../../../../../store/Walkthrough.store';

import Screen, { ScreenBody, ScreenFooter } from '../../../atoms/Screen/Screen';
import NoteField from '../../../atoms/Form/Fields/AddNote/AddNote';
import Button from '../../../atoms/Button/Button';
import Tooltip from '../../../atoms/Tooltip/Tooltip';

import './Note.scss';
import Icon from '../../../atoms/Icon/Icon';

const Note = () => {
  const { userType, currentWalkthroughItemIndex, copy } = useAppStateStore().data;
  const {
    data: { items },
    actions: { handleSave },
  } = useWalkthroughStore();
  const noteLabel = copy.screens.note;
  const title = userType === 1 ? noteLabel.note_field_label : noteLabel.title;
  const [note, setNote] = useState(items[currentWalkthroughItemIndex].note);
  const [isSaving, setIsSaving] = useState(false);
  const formData = [...items];

  const handleOnChange = val => {
    setNote(val);
    formData[currentWalkthroughItemIndex].note = val;
  };

  const handleSubmit = async () => {
    if (note !== undefined || note !== '') {
      // prepare / validate data
      // call state action
      setIsSaving(true);
      const res = await handleSave({ items: formData });

      // If successful
      if (res) {
        setIsSaving(false);
      }
    }
  };

  return (
    <>
      <Screen title={title} classes="wt-screen--note">
        {userType === 1 && (
          <>
            <Tooltip text={copy.screens.other.help_text}>
              <Icon type="help" />
            </Tooltip>
            <ScreenBody>
              <div className="wt-note">
                <div className="wt-note__form">
                  <NoteField
                    value={note}
                    placeholder={noteLabel.note_field_default_value}
                    onChange={handleOnChange}
                  />
                </div>
              </div>
            </ScreenBody>
            <ScreenFooter>
              <div className="wt-form-input">
                <Button
                  label={!isSaving ? noteLabel.submit_cta_label : 'Saving'}
                  classes="cta-btn"
                  onClick={handleSubmit}
                  disabled={note === undefined || note === ''}
                />
              </div>
            </ScreenFooter>
          </>
        )}
        {userType === 2 && (
          <ScreenBody>
            <div className="wt-note">
              <div className="wt-note__form">
                {items[currentWalkthroughItemIndex].note || noteLabel.note_field_default_value}
              </div>
            </div>
          </ScreenBody>
        )}
      </Screen>
    </>
  );
};

export default Note;
