import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import useAppStateStore from '../../../../../../store/AppState.store';
import useShareStore from '../../../../../../store/Share.store';

import Autocomplete from '../../../../atoms/Form/Controls/Autocomplete/Autocomplete';
import Label from '../../../../atoms/Form/Controls/Label/Label';

import './ContactAddClient.scss';

const ContactAddClient = () => {
  const {
    data: { copy, users },
  } = useAppStateStore();
  const {
    data: { activePOC },
    actions: { handleAddClient },
  } = useShareStore();

  const [clients, setClients] = useState(activePOC.clients || []);
  const [input, setInput] = useState(null);

  const handleChange = val => {
    let newValue;

    if (val !== undefined) {
      newValue = {
        id: val.ID,
        email: val.email,
        firstname: val.first_name,
        lastname: val.last_name,
        name: val.name,
      };
    }

    setInput(newValue);
  };

  const handleAdd = () => {
    const newClients = clients || [];
    newClients.push(input);

    if (input !== undefined && input !== null) handleAddClient(newClients);
    setInput(null);
  };

  useEffect(() => {
    setClients(activePOC.clients);
  }, [activePOC]);

  return (
    <div className="wt-poc__add-new">
      <div className="wt-share__header">
        <h3>Add Client Access</h3>
      </div>

      <div className="wt-poc__add-new-body">
        <div className="wt-form-input wt-form-input-autocomplete">
          <Label field="poc-ad" text="Add Client Access" hidden />
          <Autocomplete
            name="poc-add"
            value={input}
            options={users.subs}
            onChange={handleChange}
            searchBy="name"
          />
        </div>
        <button className="wt-poc__add-new-btn wt-cta-button" onClick={handleAdd}>Add</button>
      </div>
      <div className="wt-helptext">{copy.screens.share.email_field_help_text}</div>
    </div>
  );
};

ContactAddClient.propTypes = {

};

export default ContactAddClient;
