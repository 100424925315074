/* eslint-disable jsx-a11y/control-has-associated-label  */
/* eslint-disable max-len */
import React from 'react';

import useAppStateStore from '../../../../store/AppState.store';

import './List.scss';

import Icon from '../Icon/Icon';

const List = ({ list, external, type = 'default', limit = null }) => {
  const {
    data: { userType, currentPageViewing },
    actions: { handleBrowsePages },
  } = useAppStateStore();
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  if (list === null || typeof list === 'undefined') {
    return <div>No items.</div>;
  }

  return (
    <>
      <ol className="wt-list">
        {list.map((item, i) => {
          const { link, date, id, title } = item;
          const isCurrentPage = currentPageViewing === link;
          const localDate = new Date(date);
          const wtMonth = months[localDate.getMonth()];
          const wtDay = localDate.getDate();

          if (limit && i + 1 > parseInt(limit)) {
            return false;
          }

          return (
            <li className={`wt-list__item ${isCurrentPage ? 'wt-list__item--active' : ''}`} key={id}>
              {userType === 1 ? (
                <>
                  {type === 'default' ? (
                    <>
                      <button
                        data-link={item.link}
                        onClick={!isCurrentPage ? handleBrowsePages : null}
                        dangerouslySetInnerHTML={{ __html: title }}
                      />
                      {list.length > 1 && (
                        <button className="wt-sort-btn" aria-label="Re-order">
                          <Icon type="order" />
                        </button>
                      )}
                    </>
                  ) : (
                    <>
                      <a href={`${link}?context=edit`} rel="noopener noreferrer" dangerouslySetInnerHTML={{ __html: title }} />
                      <span className="wt-list__date">{`${wtMonth} ${wtDay}`}</span>
                    </>
                  )}
                </>
              ) : (
                external ? (
                  <a href={link} target="_blank" rel="noopener noreferrer" dangerouslySetInnerHTML={{ __html: title }} />
                ) : (
                  <button
                    data-link={item.link}
                    onClick={!isCurrentPage ? handleBrowsePages : null}
                    dangerouslySetInnerHTML={{ __html: title }}
                  />
                )
              )}
            </li>
          );
        })}
      </ol>
      {type === 'existing' ? (<a href="/walkthroughs" className="wt-list__see-all">See All</a>) : ''}
    </>
  );
};

export default List;
