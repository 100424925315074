/* eslint-disable prefer-destructuring */
const focusableElementsSelector = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
const tabKeycode = 9;

let container;
let lastFocusedElement;
let containerFirstFocusable;
let containerLastFocusable;
let shouldLoop;

const trapFocus = (containerElement, lastFocused, loop = true) => {
  container = containerElement;
  lastFocusedElement = lastFocused;
  shouldLoop = loop;
  const containerFocusable = container.querySelectorAll(focusableElementsSelector);

  if (containerFocusable.length !== 0) {
    containerFirstFocusable = containerFocusable[0];
    containerLastFocusable = containerFocusable[containerFocusable.length - 1];

    // give focus to first focusable element
    containerFirstFocusable.focus();

    container.addEventListener('keydown', loopFocus);
  }
};

const unTrapFocus = () => {
  container.removeEventListener('keydown', loopFocus);

  if (lastFocusedElement !== undefined) {
    lastFocusedElement.focus();
  }
};

function loopFocus(event) {
  if (event.keyCode === tabKeycode) {
    if (!event.shiftKey && document.activeElement === containerLastFocusable) {
      event.preventDefault();

      if (shouldLoop) {
        containerFirstFocusable.focus();
      } else {
        unTrapFocus();
      }
    } else if (event.shiftKey && document.activeElement === containerFirstFocusable) {
      event.preventDefault();

      if (shouldLoop) {
        containerLastFocusable.focus();
      } else {
        unTrapFocus();
      }
    }
  }
}

export default { trapFocus, unTrapFocus };
