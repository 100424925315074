import React, { useState, useEffect } from 'react';

import useAppStateStore from '../../../../../../store/AppState.store';
import useShareStore from '../../../../../../store/Share.store';

import Conclusion from '../../../../atoms/Form/Fields/Conclusion/Conclusion';
import Tooltip from '../../../../atoms/Tooltip/Tooltip';
import Icon from '../../../../atoms/Icon/Icon';

const ContactConclusion = () => {
  const {
    data: { copy },
  } = useAppStateStore();
  const {
    data: { activePOC },
    actions: { handleUpdateConclusion },
  } = useShareStore();

  const conclusionLabel = copy.screens.conclusion;
  const [newConclusion, setNewConclusion] = useState(activePOC.conclusion);

  const handleOnChange = val => {
    handleUpdateConclusion(val);
    setNewConclusion(val);
  };

  useEffect(() => {
    setNewConclusion(activePOC.conclusion);
  }, [activePOC]);

  return (
    <div className="wt-conclusion">
      <div className="wt-conclusion-input">
        <div className="wt-conclusion-heading">
          <h3>{copy.screens.conclusion.conclusion_field_label}</h3>
          <Tooltip text={copy.screens.other.help_text}>
            <Icon type="help-dark" theme="light" />
          </Tooltip>
        </div>
        <Conclusion
          id={`conclusion-${activePOC.point_of_contact.id}`}
          value={newConclusion}
          placeholder={conclusionLabel.conclusion_field_default_value}
          onChange={handleOnChange}
        />
      </div>
    </div>
  );
};

export default ContactConclusion;
