/* eslint-disable no-console */
import React, { createRef, useState, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';

import './Autocomplete.scss';

const Autocomplete = ({
  type,
  name = '',
  required,
  value,
  placeholder = null,
  options,
  classes,
  onChange,
  searchBy,
  selectedOptions,
}) => {
  const classNames = [
    'wt-form-input__control',
    'wt-form-input__control-text',
    classes,
  ].join(' ');

  const [userInput, setUserInput] = useState(null);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [showOptions, setShowOptions] = useState(false);
  const [activeOptIndex, setActiveOptIndex] = useState(-1);
  const [activeOptItem, setActiveOptItem] = useState(null);
  const inputRef = createRef();

  const handleFocus = e => {
    e.target.parentNode.classList.add('has-focus');

    if (filteredOptions.length > 0) {
      handleShowOption(true);
    }
  };

  const handleBlur = e => {
    e.target.parentNode.classList.remove('has-focus');

    handleCheckValue(e);
  };

  const handleCheckValue = e => {
    const target = e.target || e;
    const parent = target.parentNode;
    const { value } = target;

    if (type === 'multiple') {
      if (required && value === '' && selectedOptions.length === 0) {
        parent.classList.add('has-error');
      } else {
        parent.classList.remove('has-error');
      }

      return;
    }

    if (value !== '') {
      parent.classList.add('has-value');
      parent.classList.remove('has-error');
    } else {
      parent.classList.remove('has-value');
      setUserInput(null);
      handleShowOption(false);
      onChange(undefined);
    }

    if ((required && value === '')) {
      parent.classList.add('has-error');
    } else {
      parent.classList.remove('has-error');
    }
  };

  const handleChange = e => {
    const inputValue = e.currentTarget.value;

    if (inputValue !== '') {
      let results;
      // Filter options
      if (searchBy === 'name') {
        results = options.filter(option => (
          option.first_name.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
          || option.last_name.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
        ));
      }

      if (searchBy === 'email') {
        results = options.filter(option => (
          option.email.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
        ));
      }

      setFilteredOptions(results);
      handleShowOption(true);
    } else {
      setActiveOptIndex(-1);
      setActiveOptItem(null);
      setFilteredOptions([]);
      handleShowOption(false);
    }
  };

  const handleKeyUp = e => {
    const key = e.key || e.keyCode;
    const optionsList = document.querySelector('.wt-form-input-autocomplete__options-list');

    if (optionsList) {
      const optsItems = optionsList && optionsList.querySelectorAll('li');
      const optsLength = optsItems.length;

      const setActiveClass = id => {
        const currentActive = optsItems[id];
        const active = optionsList.querySelector('li.is-active');
        if (active) {
          active.classList.remove('is-active');
        }
        currentActive.classList.add('is-active');
        currentActive.querySelector('button').focus();
        setActiveOptItem(currentActive);
      };

      if (key === 'Escape') {
        handleShowOption(false);
      } else if (key === 'ArrowUp') {
        if (activeOptIndex > -1 || activeOptIndex === optsLength - 1) {
          if (activeOptIndex - 1 !== -1) {
            setActiveOptIndex(activeOptIndex - 1);
            setActiveClass(activeOptIndex - 1);
          } else {
            const { currentTarget } = e;
            const inputElem = currentTarget.closest('.wt-form-input').querySelector('.wt-form-input__control');
            const active = optionsList.querySelector('li.is-active');
            setActiveOptIndex(activeOptIndex - 1);
            if (inputElem && active) {
              active.classList.remove('is-active');
              inputElem.focus();
            }
          }
        } else {
          return false;
        }
      } else if (key === 'ArrowDown') {
        if (activeOptIndex === -1 || activeOptIndex < optsLength - 1) {
          setActiveOptIndex(activeOptIndex + 1);
          setActiveClass(activeOptIndex + 1);
        } else {
          return false;
        }
      }
    }
  };

  const handleShowOption = show => {
    setShowOptions(show);
  };

  const handleSelectOption = (e, selected) => {
    const { currentTarget } = e;
    const inputElem = currentTarget.closest('.wt-form-input').querySelector('.wt-form-input__control');
    handleShowOption(false);
    setFilteredOptions([]);
    setActiveOptIndex(-1);
    setActiveOptItem(null);

    if (onChange) {
      if (inputElem) {
        inputElem.focus();
        handleShowOption(false);
      }
      onChange(selected);
    }
  };

  useLayoutEffect(() => {
    if (type === 'single' && inputRef.current) {
      if ((value !== null && value !== undefined)) {
        inputRef.current.parentNode.classList.add('has-value');
        if (searchBy === 'name') {
          inputRef.current.value = `${value.firstname} ${value.lastname}`;
          setUserInput(`${value.firstname} ${value.lastname}`);
        } else {
          inputRef.current.value = value.email;
          setUserInput(value.email);
        }
      }

      if (value === undefined || value === null) {
        inputRef.current.value = '';
        inputRef.current.parentNode.classList.remove('has-value');
        setUserInput(null);
      }
    }

    inputRef.current.focus();
  }, [value]);

  return (
    <>
      <input
        id={name}
        name={name}
        type="text"
        autoComplete="off"
        className={classNames}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handleChange}
        onKeyUp={handleKeyUp}
        defaultValue={userInput}
        placeholder={placeholder}
        ref={inputRef}
      />

      {showOptions && (
        <div className="wt-form-input-autocomplete__options">
          <ul className="wt-form-input-autocomplete__options-list">
            {filteredOptions.length !== 0 ? (
              filteredOptions.map((option, i) => {
                const isSelected = selectedOptions.indexOf(option.ID) > -1;

                return (
                  <li key={i} className={isSelected ? 'is-selected' : ''}>
                    <button
                      type="button"
                      onClick={e => (!isSelected ? handleSelectOption(e, option) : null)}
                      onKeyUp={handleKeyUp}
                      tabIndex={isSelected ? '-1' : '0'}
                    >
                      {searchBy === 'name' && (
                        `${option.first_name} ${option.last_name}`
                      )}
                      {searchBy === 'email' && option.email}
                    </button>
                  </li>
                );
              })
            ) : (
              <li className="no-result">No match found.</li>
            )}
          </ul>
        </div>
      )}
    </>
  );
};

Autocomplete.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.instanceOf(Array).isRequired,
  selectedOptions: PropTypes.instanceOf(Array),

  classes: PropTypes.string,
  searchBy: PropTypes.string,
  type: PropTypes.string,

  required: PropTypes.bool,
  onChange: PropTypes.func,
};

Autocomplete.defaultProps = {
  classes: '',
  required: false,
  onChange: null,
  searchBy: 'name',
  type: 'single',
  selectedOptions: [],
};

export default Autocomplete;
