import React from 'react';
import PropTypes from 'prop-types';

import './Heading.scss';

const headings = [
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
];

const Heading = ({ level, classes = null, label, children, ...props }) => {
  const HTag = headings[level - 1] || 'h1';

  return (
    <HTag className={`wt-heading ${classes}`} {...props}>
      {label || children}
    </HTag>
  );
};

Heading.propTypes = {
  level: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  classes: PropTypes.string,
};

Heading.defaultProps = {
  classes: '',
};

export default Heading;
