import React from 'react';
import PropTypes from 'prop-types';

import useAppStateStore from '../../../../../../store/AppState.store';
import useShareStore from '../../../../../../store/Share.store';

// import SelectionList from '../../../../atoms/Form/Controls/SelectionList/SelectionList';
import ContactClients from '../ContactClients/ContactClients';
import ContactAddClient from '../ContactAddClient/ContactAddClient';

import Tooltip from '../../../../atoms/Tooltip/Tooltip';
import Icon from '../../../../atoms/Icon/Icon';

import './ContactClientManager.scss';

const ContactClientManager = () => {
  const {
    data: { copy },
  } = useAppStateStore();

  const {
    data: { activePOC },
    actions: { handleIsAddingClient },
  } = useShareStore();

  const { firstname } = activePOC.point_of_contact;

  const handleAddClients = () => {
    handleIsAddingClient();
  };

  return (
    <div className="wt-poc-client-manager">
      <div className="wt-share__header">
        <h3>Manage {firstname}'s Client Access</h3>

        <Tooltip text={copy.screens.other.help_text}>
          <Icon type="help-dark" theme="light" />
        </Tooltip>
      </div>

      <ContactClients heading="Clients with Access" />

      <ContactAddClient />

      <button className="wt-button wt-button--on-white" onClick={handleAddClients}>Done</button>
    </div>
  );
};

ContactClientManager.propTypes = {

};

export default ContactClientManager;
