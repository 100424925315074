import React from 'react';
import PropTypes from 'prop-types';

import './Button.scss';

const Button = ({ theme = 'dark', label, children, classes, ref, ...props }) => {
  const className = [
    'wt-button',
    classes,
    theme !== 'dark' ? 'wt-button--on-white' : '',
  ].join(' ');

  return (
    <button
      type="button"
      className={className}
      ref={ref}
      {...props}
    >
      {label || children}
    </button>
  );
};

Button.propTypes = {
  label: PropTypes.string.isRequired,
  classes: PropTypes.string,
  onClick: PropTypes.func,
};

Button.defaultProps = {
  classes: '',
  onClick: null,
};

export default Button;
