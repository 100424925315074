/* eslint-disable max-len */
import React, { useRef } from 'react';
import FocusTrap from 'focus-trap-react';

import Icon from '../Icon/Icon';
import Logo from '../Logo/Logo';

import './Modal.scss';

const Modal = ({ isModalOpen, heading, children, onClose, type, classes }) => {
  const modalRef = useRef(null);

  return (
    <FocusTrap
      focusTrapOptions={{
        onActivate: () => {
        },
        onDeactivate: () => {
        },
        initialFocus: () => {
          return modalRef.current.querySelector('.wt-modal__header');
        },
      }}
      active={isModalOpen}
    >
      <div
        className={[
          'wt-modal',
          isModalOpen ? 'wt-modal--is-open' : '',
          classes,
        ].join(' ')}
        aria-hidden={isModalOpen ? 'false' : 'true'}
        data-type={type}
        role="dialog"
      >
        <div className="wt-container" ref={modalRef}>
          <div className="wt-modal__content">
            <div className="wt-modal__header">
              <div className="wt-modal__logo">
                {!heading && <Logo />}
                {heading && (
                  <h1 className="wt-title-text wt-title-text--5">{heading}</h1>
                )}
              </div>
              <button
                className="wt-modal__close"
                onClick={onClose}
                aria-label="Close modal overlay"
              >
                <Icon type="close-dark" theme="light" />
              </button>
            </div>
            <div className="wt-modal__panel">{children}</div>
          </div>
        </div>
      </div>
    </FocusTrap>
  );
};

Modal.propTypes = {};

Modal.defaultProps = {};

export default Modal;
