/* eslint-disable import/prefer-default-export */
const API_URL = window.dataLayer_content ? '/wp-json' : `${process.env.REACT_APP_API_URL}/wp-json`;

async function fetchThis(route, method, data) {
  const fetcher = await fetch(`${API_URL}/${route}`, {
    method,
    body: data,
    headers: {
      'Content-Type': 'application/json',
      'X-WP-Nonce': window.nonce,
    },
  })
    .then(res => res.json())
    .then(resData => {
      return resData;
    })
    .catch(err => {
      console.log(`Error Reading data ${err}`);
    });

  return fetcher;
}

// ENDPOINTS
export function getDictionary() {
  return fetchThis('fas/settings', 'GET', null);
}

export function getUsers_Authors() {
  return fetchThis(`user/query?&filter_by=name&type=all_except_subs&_wpnonce=${window.nonce}`, 'GET', null);
}

export function getUsers_Subs() {
  return fetchThis(`user/query?&filter_by=name&type=all_users&_wpnonce=${window.nonce}`, 'GET', null);
}

export function getWalkthrough(id) {
  return fetchThis(`wp/v2/walkthrough/${id}`, 'GET', null);
}

export function addWalkthrough(data) {
  return fetchThis('wp/v2/walkthrough', 'POST', data);
}

export function updateWalkthrough(data, id) {
  return fetchThis(`wp/v2/walkthrough/${id}`, 'POST', data);
}

export function deleteWalkthrough(id) {
  return fetchThis(`wp/v2/walkthrough/${id}`, 'DELETE', null);
}

export function getWalkthroughEdit(id) {
  return fetchThis(`wp/v2/walkthrough/${id}?context=edit`, 'GET', null);
}

export function getExistingWalkthroughList() {
  return fetchThis('wp/v2/walkthrough?context=edit', 'GET', null);
}

// export function createNewWalkthrough() {
//   return fetchThis(`walkthrough/${id}`, 'GET', null);
// }

// // ADD NEW WT
// Method: POST
// Url: /wp-json/wp/v2/walkthrough

// // LIST USER WT
// Method: GET
// Url: /wp-json/wp/v2/walkthrough

// Get all of the existing walkthrough list
// Method: GET
// Url: /wp-json/wp/v2/walkthrough?context=edit

// // READ WT
// Method: GET
// Url: /wp-json/wp/v2/walkthrough/{POST_ID}

// // EDIT WT
// Method: GET
// Url: /wp-json/wp/v2/walkthrough/{POST_ID}?context=edit

// // UPDATE WT (Pass the object to update)
// Method: POST
// Url: /wp-json/wp/v2/walkthrough/{POST_ID}

// // DELETE
// Method: DELETE
// Url: /wp-json/wp/v2/walkthrough/{POST_ID}
