import React from 'react';

import './Counter.scss';

const Counter = ({ current, total, classes }) => {
  return (
    <div className={`wt-counter ${classes}`} aria-label={`Viewing ${current} of ${total} pages.`}>
      {current} of {total}
    </div>
  );
};

export default Counter;
